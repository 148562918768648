import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { organizationSettingsState } from '../../../App';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import { _setting } from '../../../utils/state/model/implementations/ImplementationFactory';

export interface OrganizationSettingsState {
  timeTrackingEnabled: boolean;
  linkedToQM: boolean;
}

const useOrganizationSettings = () => {
  const setOrganizationSettings = useSetRecoilState(organizationSettingsState);

  useEffect(() => {
    const callback = ((e: CustomEvent) => {
      if (e.detail) {
        const settings = {} as OrganizationSettingsState;
        e.detail.forEach((setting: any) => {
          if (setting.key === 'time_tracking_enabled') {
            settings.timeTrackingEnabled = setting.value === 'true';
          } else if (setting.key === 'qm_sp_id') {
            settings.linkedToQM = setting.value !== null && setting.value !== undefined;
          }
        });
        setOrganizationSettings(settings);
      }
    }) as EventListener;

    //We need to make sure to set the organization settings to recoil when the app loads
    if (getFlag(EnvironmentConfig.ORG_SETTINGS)) {
      _setting.isTimeTrackingEnabled().then((isTimeTrackingEnabled) => {
        setOrganizationSettings((prev) => ({ ...prev, timeTrackingEnabled: isTimeTrackingEnabled }));
      });
    }

    document.addEventListener('orgSettingsUpdated', callback);

    return () => {
      document.removeEventListener('orgSettingsUpdated', callback);
    };
  }, []);
};

export default useOrganizationSettings;
