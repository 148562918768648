import { IonBackdrop, IonButton, IonCol, IonIcon, IonLabel, IonRow, IonText } from '@ionic/react';
import { trashOutline, brushSharp, openOutline } from 'ionicons/icons';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CustomTextarea from '../../general/CustomTextarea';

import './InstructionsLink.css';
import { _task } from '../../../utils/state/model/implementations/ImplementationFactory';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import DeleteConfirmation from '../../general/DeleteConfirmation';
import { useTranslation } from 'react-i18next';
import ScrollIntoView from '../../general/ScrollIntoView';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/core';
import { MaxFieldLengths } from '../../../constants/constants';

interface InstructionsLinkProps {
  task: TaskDO;
  editable: boolean;
  setDisplayLink: Dispatch<SetStateAction<boolean>>;
  handleFieldClickEvent: () => void;
  handleFieldBlurEvent: () => void;
}

const InstructionsLink: React.FC<InstructionsLinkProps> = ({
  task,
  editable,
  setDisplayLink,
  handleFieldClickEvent,
  handleFieldBlurEvent,
}) => {
  const [showLinkEditor, setShowLinkEditor] = useState<boolean>(
    () => task.linkToInstructionsUrl === '' || !task.linkToInstructionsUrl
  );
  const [linkUrlValue, setLinkUrlValue] = useState<string>(() => task.linkToInstructionsUrl ?? '');
  const [linkTextValue, setLinkTextValue] = useState<string>(() => task.linkToInstructionsText ?? '');
  const [isConfirmationBoxOn, setIsConfirmationBoxOn] = useState(false);

  useEffect(() => {
    if (showLinkEditor) handleFieldClickEvent();

    let instructionsLinkKeyboardDidHideEvent: any;

    const handleKeyboardResize = async () => {
      if (isPlatform('hybrid')) {
        if (showLinkEditor) await Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });

        instructionsLinkKeyboardDidHideEvent = await Keyboard.addListener('keyboardDidHide', async () => {
          await Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        });
      }
    };

    handleKeyboardResize();

    return () => {
      instructionsLinkKeyboardDidHideEvent?.remove();
    };
  }, [showLinkEditor]);

  const { t } = useTranslation();

  const onSaveLinkClickHandler = () => {
    if (linkUrlValue === '' || !isValidURL(linkUrlValue)) return;
    const sanitizedInput = linkUrlValue.split(' ')[0];
    _task.updateTaskLinkToInstructions(task.taskId, sanitizedInput, linkTextValue);
    handleFieldBlurEvent();
    setShowLinkEditor(false);
  };

  const onDeleteLinkClickHandler = () => {
    if (task.linkToInstructionsUrl === '' || !task.linkToInstructionsUrl) {
      handleFieldBlurEvent();
      setDisplayLink(false);
    }
    _task.updateTaskLinkToInstructions(task.taskId, '', '');
    handleFieldBlurEvent();
    setDisplayLink(false);
  };

  return (
    <>
      {/* Add Link Menu */}
      {showLinkEditor && editable && (
        <>
          <IonBackdrop visible />
          <IonRow>
            <IonLabel class="ion-padding-start ion-padding-end">URL</IonLabel>
            {/* We might need to use a different component if we want to validate the URL and display an error message */}
            {/* We could also display a custom error message and keep the same component */}
            {/* The ion-input component might be a better option */}
            <CustomTextarea
              name="addInputType"
              placeholder="https://www.example.com"
              value={linkUrlValue}
              accessibilityLabel="Instructions URL"
              maxlength={MaxFieldLengths.TASK_INSTRUCTIONS_LINK_URL}
              doesEnterKeyBlur={true}
              onIonBlur={() => null}
              onIonChange={(event) => setLinkUrlValue(event.detail.value ?? '')}
              shouldNotScroll
            />
          </IonRow>
          <IonRow>
            <IonLabel class="ion-padding-start ion-padding-end">{t('instructions.link.textToDisplay.label')}</IonLabel>
            <CustomTextarea
              name="addInputType"
              placeholder={t('instructions.link.textToDisplay.placeholder')!}
              value={linkTextValue}
              accessibilityLabel="Instructions Text"
              maxlength={MaxFieldLengths.TASK_INSTRUCTIONS_LINK_TEXT}
              doesEnterKeyBlur={true}
              onIonBlur={() => null}
              onIonChange={(event) => setLinkTextValue(event.detail.value ?? '')}
              shouldNotFocus
              shouldNotScroll
            />
          </IonRow>
          <IonRow class="ion-justify-content-center ion-align-items-center">
            <IonButton
              fill="clear"
              size="large"
              color="error"
              style={{ zIndex: '100' }}
              onClick={() => (task.linkToInstructionsUrl ? setIsConfirmationBoxOn(true) : onDeleteLinkClickHandler())}
            >
              {task.linkToInstructionsUrl ? t('instructions.link.delete') : t('cancel')}
            </IonButton>
            <IonButton
              className="instructions-link_save-button"
              disabled={!isValidURL(linkUrlValue)}
              size="large"
              style={{ zIndex: '100' }}
              onClick={() => onSaveLinkClickHandler()}
            >
              {t('instructions.link.save')}
            </IonButton>
          </IonRow>
          <ScrollIntoView scrollToEnd />
        </>
      )}

      {/* Display Link Menu */}
      {!showLinkEditor && (
        <IonRow className="" style={{ paddingBottom: '.25rem' }}>
          <IonCol size="10" className="ion-no-padding">
            {task.linkToInstructionsUrl && task.linkToInstructionsText && (
              <IonText color="primary" className="font-20">
                <a href={`${task.linkToInstructionsUrl}`} target="_blank" rel="noreferrer">
                  {task.linkToInstructionsText}
                </a>
              </IonText>
            )}
            {task.linkToInstructionsUrl && !task.linkToInstructionsText && (
              <div
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  color: 'var(--ion-color-primary)',
                }}
              >
                <IonText color="primary" className="font-20">
                  <a href={`${task.linkToInstructionsUrl}`} target="_blank" rel="noreferrer">
                    {task.linkToInstructionsUrl}
                  </a>
                </IonText>
              </div>
            )}
          </IonCol>
          <IonCol
            className={`d-flex ion-justify-content-end ${
              editable ? 'ion-align-items-start' : 'ion-align-items-center'
            } ion-no-padding`}
            size="2"
          >
            {editable ? (
              <>
                <IonButton
                  className="ion-no-margin ion-no-padding instructions-link_action-button"
                  style={{ marginRight: '.5rem' }}
                  color="error"
                  fill="clear"
                  onClick={() => setIsConfirmationBoxOn(true)}
                >
                  <IonIcon style={{ fontSize: '1.75rem' }} slot="icon-only" icon={trashOutline} />
                </IonButton>
                <IonButton
                  className="ion-no-margin ion-no-padding instructions-link_action-button"
                  fill="clear"
                  onClick={() => setShowLinkEditor(true)}
                >
                  <IonIcon style={{ fontSize: '1.75rem' }} slot="icon-only" icon={brushSharp} />
                </IonButton>
              </>
            ) : (
              <IonButton
                className="ion-no-margin ion-no-padding instructions-link_action-button"
                fill="clear"
                target="_blank"
                href={`${task.linkToInstructionsUrl}`}
              >
                <IonIcon style={{ fontSize: '1.75rem' }} slot="icon-only" icon={openOutline} />
              </IonButton>
            )}
          </IonCol>
        </IonRow>
      )}
      <DeleteConfirmation
        isOpen={isConfirmationBoxOn}
        setIsOpen={setIsConfirmationBoxOn}
        deleteMe={() => onDeleteLinkClickHandler()}
        confirmButtonText={t('instructions.link.delete')!}
        header={t('instructions.link.delete')!}
        message={t('instructions.link.delete.confirmation.text')!}
      />
    </>
  );
};

function isValidURL(url: string) {
  try {
    if (url.includes(' ') || url.includes('/n')) return false;
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export default InstructionsLink;
