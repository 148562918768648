import {
  IonItem,
  IonCheckbox,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonActionSheet,
  IonLabel,
  IonText,
  IonModal,
  IonButton,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  close,
  trashOutline,
  ellipsisHorizontal,
  copyOutline,
  refreshOutline,
  calendarNumberOutline,
  camera,
  informationCircle,
  documentText,
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import DeleteConfirmation from '../../general/DeleteConfirmation';
import { TaskDO } from '../../../utils/state/model/interfaces/displayObjects';
import { _task, _timeTracking } from '../../../utils/state/model/implementations/ImplementationFactory';

import { dueDateText, dueDateTextStyle } from '../../calendar/utils/calendarUtils';
import CalendarView from '../../calendar/components/CalendarView';
import UserAvatar from '../../peopleorg/components/UserAvatar';
import { organizationSettingsState, writeAccessRestrictedState } from '../../../App';
import { useRecoilValue } from 'recoil';
import { AuthCache } from '@otuvy/auth';
import { showInputState } from '../AddInputType';
import AssignUserButton from '../../peopleorg/components/AssignUserButton';
import {
  taskPreviewCheckboxExplosionAnimation,
  taskPreviewEnterAnimation,
  taskPreviewExitAnimation,
} from './taskPreviewAnimations';
import AddUserIcon from '../../../assets/images/icons/Add_User_Icon.svg';
import useCamera from '../photos/state/useCamera';
import ClockMeInCompleteConfirmation from '../../timeTracking/components/ClockMeInCompleteConfirmation';
import { hasConfirmationShownInLast24Hours } from '../../timeTracking/utils/hasClockInConfirmationShown';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import { CheckboxChangeEventDetail, IonCheckboxCustomEvent } from '@ionic/core';
import useIsPhotoRequired from '../photos/state/useIsPhotoRequired';

interface TaskPreviewProps {
  task: TaskDO;
  isListOwner: boolean;
  isMultiSelectModeActive: boolean;
  isQMList: boolean;
  onMultiSelectClick: (item: TaskDO, isCheckboxChecked: boolean) => void;
  isSelected: boolean;
  openAssignMenu: (list: string | null, task: string[] | null, mode: 'assign' | 'share') => void;
}

const TaskPreview: React.FC<TaskPreviewProps> = ({
  task,
  isMultiSelectModeActive,
  isSelected,
  onMultiSelectClick,
  openAssignMenu,
  isListOwner,
  isQMList,
}) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const showInput = useRecoilValue(showInputState);
  const organizationSettings = useRecoilValue(organizationSettingsState);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [isEllipsisMenuOpen, setIsEllipsisMenuOpen] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isClockInConfirmationBoxOn, setIsClockInConfirmationBoxOn] = useState(false);
  const { isPhotoRequiredToComplete } = useIsPhotoRequired(task, isQMList);

  const { t } = useTranslation();
  const history = useHistory();

  const modalRef = useRef<HTMLIonModalElement>(null);
  const ionItemSlidingAnimationRef = useRef<HTMLIonItemSlidingElement>(null);
  const ionItemAnimationRef = useRef<HTMLIonItemElement>(null);
  const ionCheckboxAnimationRef = useRef<HTMLIonCheckboxElement>(null);
  const ionLabelAnimationRef = useRef<HTMLIonLabelElement>(null);

  const currentUserID = AuthCache.getCurrentUserId();
  const { takePhoto } = useCamera(task.taskId);
  const hasPermissionToUncomplete =
    isListOwner || task.assignedTo === currentUserID || task.completedBy === currentUserID;

  useEffect(() => {
    if (showInput || secondsBetween(new Date(), new Date(task.updatedOn)) > 1) return;
    taskPreviewEnterAnimation(ionItemSlidingAnimationRef, ionItemAnimationRef);

    function secondsBetween(date1: Date, date2: Date) {
      const difference = Math.abs(date1.getTime() - date2.getTime());
      return Math.floor(difference / 1000);
    }
  }, []);

  const itemClasses = () => {
    const classesArray = [
      'custom-checkbox',
      'padding-end',
      isMultiSelectModeActive ? 'multi-select' : null,
      isMultiSelectModeActive && isSelected ? 'multi-select-selected' : null,
      showInput ? 'disable-pointer-events' : null,
    ];
    const classesString = classesArray.filter((c) => c !== null).join(' ');
    return classesString;
  };

  const checkboxClasses = () => {
    const classesArray = [
      'custom-checkbox',
      'animated-checkbox',
      isMultiSelectModeActive ? 'multi-select' : null,
      !isMultiSelectModeActive && task.isCompleted ? 'checkbox-checked' : null,
      isWriteAccessRestricted ? 'disabled' : null,
      showInput ? 'disable-pointer-events' : null,
    ];
    const classesString = classesArray.filter((c) => c !== null).join(' ');
    return classesString;
  };

  const goToTask = () => {
    if (isMultiSelectModeActive) {
      onMultiSelectClick(task, !isSelected);
      return;
    }
    history.push(`/list/${task.listId}/task/${task.taskId}`, task);
  };

  const closeSliding = async () => {
    // since the sliding element is child to animation element, we have to getElementById, instead of useRef...
    const el = document.getElementById(task.taskId);
    if (el) {
      const slideOut = el as HTMLIonItemSlidingElement;
      slideOut.close();
    }
  };

  const duplicateMe = () => {
    _task.duplicateTask(task.taskId);
    closeSliding();
  };

  const deleteMe = () => {
    taskPreviewExitAnimation(ionItemSlidingAnimationRef, ionItemAnimationRef).then(() => _task.deleteById(task.taskId));
  };

  const handleAnimation = () => {
    if (!ionCheckboxAnimationRef.current || !ionItemAnimationRef.current) return;
    ionCheckboxAnimationRef.current.style.pointerEvents = 'none';
    ionItemAnimationRef.current.style.pointerEvents = 'none';
    taskPreviewCheckboxExplosionAnimation(ionCheckboxAnimationRef, ionLabelAnimationRef, task.isCompleted)
      .then(() => taskPreviewExitAnimation(ionItemSlidingAnimationRef, ionItemAnimationRef))
      .then(() => {
        toggleTask();
      });
  };

  const toggleTask = () => {
    _task.updateTaskCompletionStatus(task.taskId, !task.isCompleted);
  };

  const onTaskCheckboxClick = async (event: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) => {
    if (isMultiSelectModeActive) return;

    const confirmationShown = await showClockInConfirmationIfNeeded(event.detail.checked);
    if (confirmationShown) return;

    handleAnimation();
  };

  const showClockInConfirmationIfNeeded = async (isTaskCompleted: boolean): Promise<boolean> => {
    const isTimeTrackingEnabled = getFlag(EnvironmentConfig.TIME_TRACKING) && organizationSettings.timeTrackingEnabled;
    const isClockedIn = async (): Promise<boolean> => {
      //This is in a function so we don't need to await it unless the other conditions are met
      const currentWorkLog = await _timeTracking.getCurrentWorkLog();
      return currentWorkLog !== undefined && currentWorkLog !== null;
    };

    if (isTimeTrackingEnabled && isTaskCompleted && !hasConfirmationShownInLast24Hours() && !(await isClockedIn())) {
      showClockInConfirmation();
      return true;
    }
    return false;
  };

  const showClockInConfirmation = async () => {
    const currentTime = new Date().getTime();
    setIsClockInConfirmationBoxOn(true);
    localStorage.setItem('lastConfirmationTime', currentTime.toString());
  };

  const assignMe = async () => {
    openAssignMenu(task.listId, [task.taskId], 'assign');
    closeSliding();
  };

  const stopMe: React.MouseEventHandler<HTMLIonItemSlidingElement> = (event) => {
    // This is to prevent the focus being blurred and then taken by the accordion header
    event.preventDefault();
  };

  const onCalendarCancelClickHanlder = () => {
    setIsCalendarOpen(false);
    modalRef.current?.dismiss();
  };

  const onAddPhotoClickHandler = async () => {
    await takePhoto();
  };

  const assignmentIndicator = (): JSX.Element => {
    if (task.assignedTo) {
      return <UserAvatar size="medium" userId={task.assignedTo} />;
    } else {
      if (!isMultiSelectModeActive && isListOwner) {
        return <AssignUserButton />;
      } else {
        return <></>;
      }
    }
  };

  const ellipsisMenu: JSX.Element = (
    <>
      <IonActionSheet
        isOpen={isEllipsisMenuOpen}
        buttons={[
          {
            text: t('assignTask') ?? undefined,
            icon: AddUserIcon,
            handler: assignMe,
          },
          {
            text: t('duplicateTask') ?? undefined,
            icon: copyOutline,
            handler: duplicateMe,
          },
          {
            text: t('dueDateAndRepeat') ?? undefined,
            icon: calendarNumberOutline,
            handler: () => setIsCalendarOpen(true),
          },
          {
            text: t('cancel') ?? undefined,
            role: 'cancel',
            icon: close,
            //handler: closeSliding //needs to be part of onWillDismiss
          },
        ]}
        onWillDismiss={() => {
          setIsEllipsisMenuOpen(false);
          closeSliding();
        }}
      />
      {isListOwner && (
        <DeleteConfirmation
          isOpen={isDeleteConfirmationOpen}
          setIsOpen={setIsDeleteConfirmationOpen}
          deleteMe={deleteMe}
          confirmButtonText="confirmation.delete.task.confirm"
          header="confirmation.delete.task.header"
          message="confirmation.delete.task.message"
        />
      )}
    </>
  );

  return (
    <>
      <IonItemSliding
        key={task.taskId}
        id={task.taskId}
        ref={ionItemSlidingAnimationRef}
        onMouseDown={stopMe}
        disabled={isWriteAccessRestricted || !isListOwner} //TODO: as long as everything in the slide out menu is restrictd to only owners this will work.  If we add anything that non-owners can do we will need to change this
      >
        <IonItem ref={ionItemAnimationRef} lines="none" className={itemClasses()} onClick={goToTask}>
          {/* This IonButton wrapper prevents clicking the item from triggering the checkbox */}
          <IonButton
            fill="clear"
            slot="start"
            className={`ion-no-margin ion-align-self-start ${
              !isMultiSelectModeActive && task.isCompleted && !hasPermissionToUncomplete && 'disable-pointer-events'
            }`}
            onClick={(event) => !isMultiSelectModeActive && event.stopPropagation()}
          >
            {' '}
            {isPhotoRequiredToComplete && !isMultiSelectModeActive && !task.completedOn ? (
              <IonCheckbox
                slot="start"
                className="custom-checkbox__photoRequired"
                style={{ position: 'relative', pointerEvents: 'all', opacity: '1' }}
                disabled
                onClick={(event) => {
                  event.preventDefault();
                  onAddPhotoClickHandler();
                }}
                aria-label=""
              />
            ) : (
              <IonCheckbox
                slot="start"
                ref={ionCheckboxAnimationRef}
                style={{ position: 'relative' }}
                className={checkboxClasses()}
                color="primary"
                checked={isMultiSelectModeActive ? isSelected : task.isCompleted}
                onIonChange={onTaskCheckboxClick}
                onClick={(event) => isMultiSelectModeActive && event.preventDefault()}
                aria-label=""
              ></IonCheckbox>
            )}
          </IonButton>
          <IonLabel
            class="ion-text-wrap"
            color={task.isCompleted && !isMultiSelectModeActive ? 'light' : ''}
            ref={ionLabelAnimationRef}
            style={{ zIndex: 3 }}
          >
            {task.taskName}
            {
              <div className="d-flex ion-align-items-center">
                {
                  <IonText
                    className={`font-12 d-block`}
                    style={{ margin: `0px 2px 0px 1rem` }}
                    color={dueDateTextStyle(task)}
                  >
                    {task.dueDate && dueDateText(task.dueDate)}
                  </IonText>
                }
                {task.recurrence && (
                  <IonIcon icon={refreshOutline} style={{ margin: '0px 2px' }} color="primary" className="font-16" />
                )}
                {((task.completionPhotoIds && task.completionPhotoIds.length > 0) ||
                  (task.pendingPhotos && task.pendingPhotos.length > 0)) && (
                  <IonIcon icon={camera} style={{ margin: '0px 2px' }} color="primary" className="font-16" />
                )}
                {task.notes && (
                  <IonIcon icon={documentText} style={{ margin: '0px 2px' }} color="primary" className="font-16" />
                )}
                {task.instructions && (
                  <IonIcon icon={informationCircle} style={{ margin: '0px 2px' }} color="primary" className="font-16" />
                )}
              </div>
            }
          </IonLabel>
          <div
            className="ion-align-self-start"
            style={{ marginTop: 'calc(17 / var(--root-size) * 1rem)' }}
            onClick={(e) => {
              if (isMultiSelectModeActive) return;
              e.stopPropagation();
              if (!isWriteAccessRestricted && isListOwner) {
                openAssignMenu(task.listId, [task.taskId], 'assign');
              }
            }}
          >
            {assignmentIndicator()}
          </div>
        </IonItem>

        <IonItemOptions>
          <IonItemOption color="overlay" onClick={() => setIsEllipsisMenuOpen(true)}>
            <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
          </IonItemOption>
          <IonItemOption color="danger" onClick={() => setIsDeleteConfirmationOpen(true)}>
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      {/* ^^^ION ITEM ^^^ */}
      {ellipsisMenu}
      <IonModal isOpen={isCalendarOpen}>
        <CalendarView onCancel={onCalendarCancelClickHanlder} tasks={[task]} />
      </IonModal>
      <ClockMeInCompleteConfirmation
        isOpen={isClockInConfirmationBoxOn}
        setIsOpen={setIsClockInConfirmationBoxOn}
        onConfirmationDismiss={handleAnimation}
      />
    </>
  );
};

export default React.memo(TaskPreview, (prevProps, nextProps) => {
  let isSameUpdatedOn: boolean;
  let previousTime = -1;
  let nextTime = -1;

  if (prevProps && prevProps.task && prevProps.task.updatedOn) {
    previousTime = new Date(prevProps.task.updatedOn).getTime();
  } else {
    console.error('TaskPreview: previous task does not have a proper updatedOn property', prevProps);
  }
  if (nextProps && nextProps.task && nextProps.task.updatedOn && nextProps.task.updatedOn) {
    nextTime = new Date(nextProps.task.updatedOn).getTime();
  } else {
    console.error('TaskPreview: next task does not have proper updatedOn property', nextProps);
  }

  isSameUpdatedOn = previousTime === nextTime;

  return (
    isSameUpdatedOn &&
    prevProps.isListOwner === nextProps.isListOwner &&
    prevProps.isMultiSelectModeActive === nextProps.isMultiSelectModeActive &&
    prevProps.isSelected === nextProps.isSelected
  );
});
