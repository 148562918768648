import React, { FormEvent, useEffect, useRef, useState } from 'react';
import {
  IonItem,
  IonButton,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonPage,
  IonContent,
  useIonAlert,
  IonicSafeString,
} from '@ionic/react';
import { Device, GetLanguageCodeResult } from '@capacitor/device';
import logoDark from '../../assets/images/app-logo-dark.png';
import logoLight from '../../assets/images/app-logo-light.png';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { errorAlertMessageKeyState } from '../error/ErrorAlert';
import { Language, Settings, settingsState } from '../settings/settingsState';
import { getEnumKeyByEnumValue, logFriendlyObject } from '@otuvy/common-utils';
import i18n from '../../i18n';
import VersionStamp from '../settings/VersionStamp';
import { useSignIn } from '@otuvy/auth';
import { handleNotificationSetup } from '../notifications/notificationUtils';

/*
https://www.davidsalter.com/posts/creating-a-custom-login-page-with-aws-amplify/
https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/

Assumption is that any time they are validating they have an internet connection.
Currently, no "offline" authentication is allowed.
*/

const SignIn: React.FC = () => {
  const setErrorMessageKey = useSetRecoilState(errorAlertMessageKeyState);
  const [settings, setSettings] = useRecoilState(settingsState);

  const handleAdditionalSetup = async () => {
    // This is where we would do any additional setup after logging in, such as setting up notifications
    await handleNotificationSetup(); //TODO: this may not need to be awaited, but I didn't want to break it until after extracting things out and making sure it still worked
  };

  const {
    email,
    setEmail,
    validateEmail,
    hideRequestOtp,
    setHideRequestOtp,
    requestOtp,
    // requestNewOTP, //FIXME: This is deprecated until we update both the app, admin portal, and all packages to ionic 8 (See CC-1729 for more details)
    otpConfirmation,
    setOtpConfirmation,
    verifyOtp,
    // otpValidationFailed, //FIXME: This is deprecated until we update both the app, admin portal, and all packages to ionic 8 (See CC-1729 for more details)
  } = useSignIn({
    isAdminPortal: false, //<----------------------make sure this is set correctly for the project TODO: move to env file
    setErrorMessageKey,
    handleAdditionalSetup,
  });

  const { t } = useTranslation();
  const emailInputRef = useRef<HTMLIonInputElement | null>(null);

  // This is a temporary fix until we update both the app and admin portal to ionic 8 (See CC-1729 for more details)
  const [otpValidationFailed, setOtpValidationFailed] = useState<boolean>(false);
  const [presentNewCodeAlert] = useIonAlert();

  useEffect(() => {
    const setLanguageFromDeviceLanguage = async () => {
      try {
        const { value }: GetLanguageCodeResult = await Device.getLanguageCode();
        const twoLetterLanguageCode: string = value.slice(0, 2);
        const language: Language = Language[getEnumKeyByEnumValue(Language, twoLetterLanguageCode)];
        const updatedSettings: Settings = { ...settings, language };
        setSettings(updatedSettings);
        i18n.changeLanguage(language);
      } catch (error) {
        console.error('Failed to retrieve or change language', logFriendlyObject(error));
      }
    };
    /*
      This check exists so that we only set the language from the device language
      if it has not been set previously. If it has been set previously, we use
      the language setting that is saved in the app.
    */
    if (settings.language === undefined) {
      setLanguageFromDeviceLanguage();
    }
  }, []);

  const handleEmailSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      emailInputRef.current?.getInputElement().then((input) => input.blur()); // This is needed when submitting with the enter key to avoid a potential focus event when returning the app to the foreground
      requestOtp();
    } catch (error) {
      console.error('Failed to submit Email', logFriendlyObject(error));
    }
  };

  const handleOTPSubmit = async (event: MouseEvent | KeyboardEvent | FormEvent | CustomEvent<FocusEvent>) => {
    try {
      event.preventDefault();
      await verifyOtp();
    } catch (error) {
      console.error('Failed to submit OTP request', logFriendlyObject(error));
    }
  };

  // This is a temporary fix until we update both the app and admin portal to ionic 8 (See CC-1729 for more details)
  const requestNewOTP = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setOtpConfirmation('');
    setOtpValidationFailed(false);

    presentNewCodeAlert({
      header: t('newVerificationCodeSent.header'),
      message: new IonicSafeString(t('newVerificationCodeSent.message')),
      buttons: [t('button.acknowledge')],
    });

    requestOtp();
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid class="h-100 ion-padding-horizontal ion-align-items-center">
          <IonRow class="h-100 ion-justify-content-center ion-align-items-center">
            <IonCol size="12" sizeMd="10" sizeLg="6">
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonImg src={document.body.classList.contains('dark') ? logoDark : logoLight} />
                </IonCol>
              </IonRow>
              {/* email */}
              <IonRow class="ion-justify-content-center">
                <IonCol size="12">
                  <IonItem>
                    <form onSubmit={handleEmailSubmit} style={{ width: '100%' }}>
                      <IonInput
                        class="centered-input sign-in-input-custom-label label-text"
                        ref={emailInputRef}
                        label={t('email')}
                        labelPlacement="floating"
                        placeholder={t('placeholder.email')!}
                        value={email}
                        type={'email'}
                        autocomplete="email"
                        autofocus={true}
                        onIonInput={(event) => setEmail(event.detail.value?.toString().trim().toLowerCase() ?? '')}
                        onIonFocus={() => setHideRequestOtp(false)} // This functionality is per design
                      />
                    </form>
                  </IonItem>
                </IonCol>
              </IonRow>
              {!hideRequestOtp ? (
                // OTP Request
                <>
                  <IonRow>
                    <IonCol size="12">
                      <IonItem lines="none">
                        <IonText class="ion-text-center ion-margin-horizontal">
                          <h6>{t('otpPrompt')}</h6>
                        </IonText>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-justify-content-center">
                    <IonCol size="12">
                      <IonButton
                        color="primary"
                        className="custom-button-radius"
                        disabled={!validateEmail(email)}
                        expand="block"
                        onClick={requestOtp}
                      >
                        {t('sendCode')}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </>
              ) : (
                <>
                  {/* OTP sent message */}
                  <IonRow>
                    <IonCol size="12">
                      <IonItem lines="none">
                        <IonText class="ion-text-center ion-margin-horizontal">
                          <h6>{t('otpSent')}</h6>
                        </IonText>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  {/* OTP */}
                  <IonRow>
                    <IonCol size="12">
                      <IonItem>
                        <form style={{ width: '100%' }} onSubmit={handleOTPSubmit}>
                          <IonInput
                            class="centered-input sign-in-input-custom-label label-text"
                            label={t('otpCode')}
                            labelPlacement="floating"
                            placeholder={t('placeholder.otp')!}
                            value={otpConfirmation}
                            type={'number'}
                            pattern={'[0-9]*'}
                            autocomplete="one-time-code"
                            onIonInput={(e) => setOtpConfirmation(e.detail.value?.toString().trim() ?? '')}
                          />
                        </form>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  {otpValidationFailed ? (
                    // Wrong OTP message
                    <IonRow>
                      <IonCol size="12">
                        <IonText color="danger">
                          <h6>{t('error.signin.incorrectOTP')}</h6>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  ) : null}
                  {/* Send OTP button */}
                  <IonRow class="ion-margin-vertical">
                    <IonCol size="12">
                      <IonButton
                        color="primary"
                        className="custom-button-radius"
                        disabled={!otpConfirmation}
                        expand="block"
                        onClick={verifyOtp}
                      >
                        {t('submit')}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  {/* New OTP request */}
                  <IonRow>
                    <IonCol size="12" class="ion-text-center">
                      <a href="#" onClick={(e) => requestNewOTP(e)}>
                        {t('newCodeRequest')}
                      </a>
                    </IonCol>
                  </IonRow>
                </>
              )}
            </IonCol>
            <IonCol class="d-flex ion-justify-content-center ion-align-items-center ion-align-self-end p-absolute">
              <VersionStamp authenticated={false} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SignIn;
