import {
  IonActionSheet,
  IonGrid,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonCheckbox,
} from '@ionic/react';
import {
  archiveOutline,
  close,
  copyOutline,
  createOutline,
  ellipsisHorizontal,
  shareOutline,
  trashOutline,
} from 'ionicons/icons';
import unarchiveLogo from '../../../assets/images/icons/Unarchive-outline.svg';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ListProgressBar from './ListProgressBar';
import DeleteConfirmation from '../../general/DeleteConfirmation';
// import useToastQueue from '../../../utils/useToastQueue';
import { MaxFieldLengths } from '../../../constants/constants';
import CustomTextarea from '../../general/CustomTextarea';
import { _list } from '../../../utils/state/model/implementations/ImplementationFactory';
import { ListDO } from '../../../utils/state/model/interfaces/displayObjects';
import useIsListOwner from '../../peopleorg/hooks/useIsListOwner';
import ListPreviewUsers from '../../peopleorg/components/ListPreviewUsers';
import { writeAccessRestrictedState } from '../../../App';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { listMultiSelectModeAtom } from '../../../pages/ListsPage';

import AddUserIcon from '../../../assets/images/icons/Add_User_Icon.svg';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';

export const isRenameListInputOnState = atom<boolean>({
  key: 'isRenameListInputOnState',
  default: false,
});

interface ListPreviewProps {
  list: ListDO;
  onUserAvatarClick: (list: string | null, task: string[] | null, mode: 'assign' | 'share') => void;
  onMultiSelectClick: (item: ListDO, isCheckboxChecked: boolean) => void;
  isSelected: boolean;
}

const ListPreview: React.FC<ListPreviewProps> = ({ list, onUserAvatarClick, onMultiSelectClick, isSelected }) => {
  const { isRestricted: isWriteAccessRestricted } = useRecoilValue(writeAccessRestrictedState);
  const isMultiSelectMode = useRecoilValue(listMultiSelectModeAtom);
  const setIsRenameListInputOn = useSetRecoilState(isRenameListInputOnState);

  const [name, setName] = useState<string>(list.listName);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [isEllipsisMenuOpen, setIsEllipsisMenuOpen] = useState<boolean>(false);
  const [isRenameList, setIsRenameList] = useState<boolean>(false);
  const { isListOwner } = useIsListOwner(list);

  useEffect(() => {
    setName(list.listName);
  }, [list.listName]);

  const { t } = useTranslation();
  const history = useHistory();
  const slideOutRef = useRef<HTMLIonItemSlidingElement | null>(null);
  // const [presentToast] = useToastQueue(); // TODO: toasts will need to be reintroduced

  const updateName = () => {
    const trimmedName: string = name.trim();
    if (trimmedName.length === 0) {
      _list.renameList(list.listId, t('default.list.name'));
      setIsRenameList(false);
      setIsRenameListInputOn(false);
      return;
    }
    if (trimmedName.length > 0) {
      _list.renameList(list.listId, trimmedName);
      setIsRenameList(false);
      setIsRenameListInputOn(false);
    }
  };

  const onListPreviewClickHandler = () => {
    if (isRenameList) return;

    if (isMultiSelectMode) {
      onMultiSelectClick(list, !isSelected);
      return;
    }

    history.push(`/list/${list.listId}`);
  };

  const closeSliding = async () => slideOutRef.current?.close();

  const duplicateMe = async () => {
    _list.duplicateListById(list.listId).then(() => closeSliding());
  };

  const assignMe = async () => {
    onUserAvatarClick(list.listId, null, 'assign');
    closeSliding();
  };

  const shareMe = async () => {
    onUserAvatarClick(list.listId, null, 'share');
    closeSliding();
  };

  const archiveMe = async () => {
    _list.updateList(list.listId, { isArchived: !list.isArchived }).then(() => closeSliding());
  };

  const deleteMe = async () => await _list.deleteById(list.listId);
  const renameMe = () => {
    closeSliding();
    setIsEllipsisMenuOpen(false);
    setIsRenameListInputOn(true);
    // wait in order to avoid focus being stolen by the ion-item-option
    setTimeout(() => {
      setIsRenameList(true);
    }, 500);
  };

  const stopMe: React.MouseEventHandler<HTMLIonItemSlidingElement> = (event) => {
    if (!isRenameList) {
      // This is to prevent the focus being blurred and then taken by the accordion header
      event.preventDefault();
    }
  };

  return (
    <>
      <IonItemSliding
        ref={slideOutRef}
        disabled={isWriteAccessRestricted || !isListOwner || isRenameList || isMultiSelectMode} //This check of "isListOwner" works as long as everythign in the slide out menu is owner only.  If we add some non-owner actions to that list we will need to do this check another way
        onMouseDown={stopMe}
      >
        <IonItem
          className={`custom-checkbox ${isMultiSelectMode && 'multi-select'} ${
            isMultiSelectMode && isSelected && 'multi-select-selected'
          }`}
          style={{ '--min-height': '89px' }}
          lines="none"
          onClick={onListPreviewClickHandler}
        >
          {isMultiSelectMode && (
            <div className="d-flex ion-aling-items-starts" style={{ alignSelf: 'baseline', paddingRight: '5px' }}>
              <IonCheckbox
                className="custom-checkbox multi-select"
                color="primary"
                aria-label=""
                checked={isSelected}
                onClick={(event) => {
                  if (!isMultiSelectMode) return;
                  event.preventDefault();
                }}
              />
            </div>
          )}
          <IonGrid style={{ alignSelf: isMultiSelectMode ? 'start' : '' }} fixed={true}>
            {isRenameList ? (
              <IonRow>
                <IonCol>
                  <CustomTextarea
                    accessibilityLabel="List Name"
                    value={name}
                    maxlength={MaxFieldLengths.LIST_NAME}
                    onIonBlur={updateName}
                    onIonChange={(event) => setName(event.detail.value ?? '')}
                    doesEnterKeyBlur={true}
                    placeholder={t('default.list.name') ?? undefined}
                  />
                </IonCol>
              </IonRow>
            ) : (
              <IonRow>
                <IonCol class="ion-no-padding">
                  <IonRow class="ion-align-items-start ion-justify-content-between">
                    <IonCol class="ion-no-padding">
                      <IonLabel
                        class="ion-text-wrap"
                        color={list.isCompleted && !isMultiSelectMode ? 'light' : undefined}
                        style={{ maxWidth: '100%' }}
                      >
                        {list.listName}
                      </IonLabel>
                    </IonCol>
                  </IonRow>
                  <ListProgressBar numItems={list.numTasks} numItemsCompleted={list.numCompletedTasks} />
                </IonCol>
                <ListPreviewUsers
                  isEditable={!isWriteAccessRestricted && isListOwner && !isMultiSelectMode}
                  list={list}
                  onUserAvatarClick={onUserAvatarClick}
                />
              </IonRow>
            )}
          </IonGrid>
        </IonItem>

        <IonItemOptions>
          <IonItemOption color="overlay" onClick={() => setIsEllipsisMenuOpen(true)}>
            <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
          </IonItemOption>
          <IonItemOption color="danger" onClick={() => setIsDeleteConfirmationOpen(true)}>
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
      {isListOwner && (
        <DeleteConfirmation
          isOpen={isDeleteConfirmationOpen}
          setIsOpen={setIsDeleteConfirmationOpen}
          deleteMe={deleteMe}
          confirmButtonText="confirmation.delete.list.confirm"
          header="confirmation.delete.list.header"
          message="confirmation.delete.list.message"
        />
      )}
      <IonActionSheet
        isOpen={isEllipsisMenuOpen}
        buttons={[
          {
            text: t('assignList')!,
            icon: AddUserIcon,
            handler: assignMe,
          },
          {
            text: getFlag(EnvironmentConfig.LIST_OWNER_UI)
              ? t('menu.action.shareListOrAddOwners')!
              : t('menu.action.share.list')!,
            icon: shareOutline,
            handler: shareMe,
          },
          {
            text: t('duplicateList')!,
            icon: copyOutline,
            handler: duplicateMe,
          },
          {
            text: t('list.rename')!,
            icon: createOutline,
            handler: renameMe,
          },
          {
            text: list.isArchived ? t('list.unarchive')! : t('list.archive')!,
            icon: list.isArchived ? unarchiveLogo : archiveOutline,
            handler: archiveMe,
          },
          {
            text: t('cancel')!,
            role: 'cancel',
            icon: close,
            handler: closeSliding,
          },
        ]}
        onWillDismiss={() => setIsEllipsisMenuOpen(false)}
      />
    </>
  );
};

export default React.memo(ListPreview); // TODO: will memo cause problems since we can edit the contents of the preview?
