// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files
const resources = {
  en: {
    translation: {
      "addFirstList": "Tap the \"+\" \n to add a list",
      "addFirstTask": "Tap the \"+\" \n to add a task",
      "addList": "Add List",
      "addTask": "Add Task",
      "after": "After",
      "alert.offline.photo.header": "Currently Offline",
      "alert.offline.photo.message": "Please go back online to download images.",
      "archive": "Archive",
      "archived": "Archived",
      "assign": "Assign",
      "assignList": "Assign List",
      "assignMenu.deleteConfirmation.header": "Overwrite Assignments",
      "assignMenu.deleteConfirmation.message": "Any of this list’s existing assignments will be removed, and then assigned to the person you selected. \n \n Note: Completed tasks will not be reassigned.",
      "assignMenu.deselectUsers.assign": "No one (Unassign)",
      "assignMenu.deselectUsers.share": "No one (Unshare)",
      "assignMenu.header.list.assign": "Assign list to:",
      "assignMenu.header.list.share": "Share list with:",
      "assignMenu.header.list.shareOwner": "Share / Add Owners:",
      "assignMenu.header.task.assign_one": "Assign task to:",
      "assignMenu.header.task.assign_other": "Assign tasks to:",
      "assignTask": "Assign Task",
      "assignTask.to": "Assign task to:",
      "banner.missedRequiredUpdate.main": "An Update is Required",
      "banner.writeAccessRestricted.info.body.nonPayment.1": "Due to past due/payment issues, your company's access has been limited.",
      "banner.writeAccessRestricted.info.body.nonPayment.2": "Please contact your admin to update payment information so your access may continue.",
      "banner.writeAccessRestricted.info.header": "Limited",
      "banner.writeAccessRestricted.main": "Access is Limited",
      "back": "Back",
      "before": "Before",
      "button.acknowledge": "OK",
      "button.later": "Later",
      "button.update": "Update",
      "button.illAddTasks": "I'll Add Tasks",
      "button.addTasksForMe": "Add Tasks For Me",
      "calendarView.clear": "Clear",
      "calendarView.repeat": "Repeat",
      "calendarView.time": "Time",
      "cancel": "Cancel",
      "change": "Change",
      "complete": "Complete",
      "completeList": "Complete List",
      "completeTask": "Complete Task",
      "completeTasks": "Complete Tasks",
      "completed": "Completed",
      "completionReport": "Completion Report",
      "confirmation.agreement.button.accept": "Accept",
      "confirmation.agreement.button.reject": "Do Not Accept",
      "confirmation.agreement.header.default": "Welcome!",
      "confirmation.agreement.header.updated": "Updated",
      "confirmation.agreement.body.default": "To use Otuvy Frontline, you'll need to accept our <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Privacy Policy</a> and <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Terms of Service</a>.",
      "confirmation.agreement.body.updated": "Our <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Terms of Service</a> and <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Privacy Policy</a> have been updated. To continue using Otuvy Frontline, you'll need to accept these updates.",
      "confirmation.delete.account.link": "Yes, delete my account",
      "confirmation.delete.image.confirm": "Delete image",
      "confirmation.delete.image.header": "Delete Image",
      "confirmation.delete.image.message": "You will not be able to recover this image once deleted.",
      "confirmation.delete.list.confirm": "Delete the list",
      "confirmation.delete.list.header": "Delete this list?",
      "confirmation.delete.list.message": "All of its tasks (and their pics, notes, etc...) will be deleted as well.",
      "confirmation.delete.profileImage.header": "Are you sure you want to remove your profile image?",
      "confirmation.delete.task.confirm": "Delete task",
      "confirmation.delete.task.generic.confirm": "Delete",
      "confirmation.delete.task.generic.header": "Confirm Deletion",
      "confirmation.delete.task.generic.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.delete.task.header": "Delete Task",
      "confirmation.delete.task.message": "Are you sure? (All pics, notes, etc... will be deleted as well.)",
      "confirmation.dueDate.apply": "Yes, apply the due date",
      "confirmation.dueDate.existingWillBeReplaced": "Any existing due date will be replaced",
      "confirmation.dueDate.replace": "Replace Due Dates?",
      "contactSupport": "Contact Support",
      "date": "Date",
      "dayOfTheWeek": "Day of the Week",
      "default.list.name": "Untitled List",
      "default.task.name": "Untitled Task",
      "deficiencies": "Deficiencies",
      "delete": "Delete",
      "deleteTasks": "Delete tasks",
      "deselect": "Deselect",
      "deselectAll": "Deselect All",
      "done": "Done",
      "dueDate": "Due Date",
      "dueDateAndRepeat": "Due Date and Repeat...",
      "duplicate": "Duplicate",
      "duplicateList": "Duplicate List",
      "duplicateTask": "Duplicate task",
      "duplicateTasks": "Duplicate tasks",
      "edit": "Edit",
      "email": "Email",
      "employeeScorecard": "Employee Scorecard",
      "error.name.required": "Name is Required",
      "error.phone.invalid": "Phone appears to be invalid",
      "error.phone.required": "Phone is Required",
      "error.signin.incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "errorAlert.default.header": "Whoops!",
      "errorAlert.default.message": "We ran into an error on our side. Sorry about that! If it happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generateList.contentFilter.message": "One or more of your answers was flagged as containing inappropriate content. Please modify your answers and try submitting again.<br/><br/>If this error happens multiple times, please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.generateList.generic.message": "We encountered an issue while creating the list. Sorry about that! You can try submitting again.<br/><br/>If it happens multiple times please feel free to <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorAlert.list.unavailable": "It looks like this isn’t available to you. If you have questions, please check with your administrator, or <a href='mailto:support@otuvy.com'>Contact Support</a>.",
      "errorPage": "Hi, I'm the Error Page.",
      "errorPage.backIntoIt": "Let's get back into it.",
      "errorPage.header": "Hi, I'm the Error Page.",
      "errorPage.message": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "errorPage.unavailableList.message": "'It looks like this list is no longer available'.",
      "errorPage.sorry": "Sorry about that, we had a hiccup on our end. Don't worry, your work should save automatically.",
      "favorites": "Favorites",
      "feedback.list.completed": "Great work! You've completed the list.",
      "feedback.task.completed": "Great work! Task completed \"{{taskName}}\"",
      "generatedTask.instructions.duration": "({{duration}} min)",
      "incorrectOTP": "Incorrect. Please double check the code from your email and enter it again.",
      "instructions": "Instructions",
      "instructions.link.add": "+ Add a Link",
      "instructions.link.delete": "Delete Link",
      "instructions.link.delete.confirmation.text": "Are you sure you want to delete this link?",
      "instructions.link.textToDisplay.label": "Text to Display (optional)",
      "instructions.link.textToDisplay.placeholder": "e.g. 10 Steps to Lawn Edging",
      "instructions.link.save": "Save Link",
      "label.selectAllCompletedTasks": "Select all completed tasks",
      "label.selectAllTasks": "Select all tasks",
      "label.task.instructions": "Instructions",
      "label.task.name": "Task",
      "label.task.notes": "Notes",
      "label.task.taskName": "Task",
      "link.deleteMyAccount": "Delete my account",
      "link.faq": "Frequently Asked Questions",
      "link.feedback": "Give Feedback",
      "link.privacyPolicy": "Privacy Policy",
      "link.support": "Contact Support",
      "link.termsAndConditions": "Terms and Conditions",
      "link.termsOfService": "Terms of Service",
      "link.voteOnFeatures": "Vote On Features",
      "list": "List",
      "list_one": "List",
      "list_other": "Lists",
      "list.archive": "Archive List",
      "list.unarchive": "Unarchive List",
      "list.owner": "List Owner",
      "list.rename": "Rename List",
      "listDetails.header": "List Details",
      "listName": "Name of List",
      "lists": "Lists",
      "loading.generateList": "Creating tasks...",
      "loading.generic": "Please wait...",
      "logout": "Log out",
      "menu.action.hideArchivedLists": "Hide Archived Lists",
      "menu.action.showArchivedLists": "Show Archived Lists",
      "menu.action.hideCompletedLists": "Hide Completed Lists",
      "menu.action.hideCompletedTasks": "Hide Completed Tasks",
      "menu.action.scanQRCodes": "Scan QR Codes",
      "menu.action.select.lists": "Select Lists",
      "menu.action.select.tasks": "Select Tasks",
      "menu.action.share.list": "Share List",
      "menu.action.shareListOrAddOwners": "Share Lists or Add Owners",
      "menu.action.showCompletedLists": "Show Completed Lists",
      "menu.action.showCompletedTasks": "Show Completed Tasks",
      "menu.action.sort": "Sort by...",
      "menu.duedateAndRepeat": "Due date and repeat",
      "menu.header.sort": "Sort by...",
      "menu.helpAndAbout": "Help & About",
      "modal.generateList.header": "Questions",
      "modal.generateList.question1.accessibilityLabel": "List Name*",
      "modal.generateList.question1.label": "Let's get started with your list",
      "modal.generateList.question1.placeholder": "List Name, etc.",
      "modal.generateList.question2.label": "What type of tasks?",
      "modal.generateList.question2.placeholder": "e.g. Cleaning, Repair, Installation, etc.",
      "modal.generateList.question3.label": "What is the main objective of the list?",
      "modal.generateList.question3.placeholder": "Give detailed tasks for sanitizing the kitchen.",
      "modal.generateList.button.accept": "Add Tasks for Me",
      "multiSelectMenu.deselect.archived": "Deselect All Archived",
      "multiSelectMenu.deselect.completed": "Deselect All Completed",
      "multiSelectMenu.deselect.incomplete": "Deselect All Incomplete",
      "multiSelectMenu.dueDateAndRepeat": "Due Date / Repeat",
      "multiSelectMenu.photoRequired": "Require Photo",
      "multiSelectMenu.photoRequired.makeOptional": "Disable Photo",
      "multiSelectMenu.select.archived": "Select All Archived",
      "multiSelectMenu.select.completed": "Select All Completed",
      "multiSelectMenu.select.incomplete": "Select All Incomplete",
      "name": "Name",
      "newCodeRequest": "Send me a new code",
      "newVerificationCodeSent.header": "Still waiting for a code?",
      "newVerificationCodeSent.message": "Use this time to meditate or check us out at <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> to learn more about us. You may also reach out to your account administrator to troubleshoot the issue.",
      "none": "None",
      "notes": "Notes",
      "notifications.notification.inApp": "In-App Notifications",
      "notifications.notification.gotoOSSettings.label": "Phone Settings",
      "notifications.listDone": "List Done",
      "notifications.tasksGetAssignedToMe": "Tasks Get Assigned to Me",
      "notifications.listsGetAssignedToMe": "Become List Owner",
      "notifications.pastDueTasks": "Past Due Tasks",
      "notifications.pastDueTasks.assignedToMe": "Assigned to me",
      "notifications.pastDueTasks.oneOffs": "One-offs",
      "notifications.pastDueTasks.repeating": "repeating",
      "notifications.pastDueTasks.onSharedList": "On a Shared List",
      "popover.generateList.offline": "You must be online to add tasks automatically.",
      "offline": "Offline",
      "offlineIndicator.popover.text": "Your phone is offline. But don’t worry! You can keep working and we’ll update everything when you’re back online.",
      "offlineNoticeHeader": "No internet \t(",
      "offlineNoticeMessage": "We should reconnect soon. To find out if it's not just our app, you can... <br/> <br/> <ul><li> Check your phone settings</li><li>Check other Apps</li><li>Check WiFi signal strength</li></ul><br/>And don't worry we save your work as we go!",
      "option.sort.activity": "Recent Activity",
      "option.sort.assignedTo": "Assigned To",
      "option.sort.created": "Creation Date",
      "option.sort.due": "Due Date",
      "option.sort.name": "Name",
      "otpCode": "Code",
      "otpPrompt": "We'll send a code to your email so you can start using Otuvy Frontline",
      "otpSent": "We've sent a verification code to your email. Please enter it below.",
      "owner": "Owner",
      "preview": "Preview",
      "phone": "Phone",
      "picturesAbbreviated": "Pics",
      "placeholder.email": "jane.doe@example.com",
      "placeholder.generic": "Tap to enter text",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Your verification code",
      "product.version": "v.",
      "registrationGreeting": "Welcome! Let's finish setting up your account...",
      "remove": "Remove",
      "repeat": "Repeat",
      "repeatMenu.custom": "Custom",
      "repeatMenu.custom.day": "Day",
      "repeatMenu.custom.days": "Days",
      "repeatMenu.custom.fifth": "Fifth",
      "repeatMenu.custom.first": "First",
      "repeatMenu.custom.fourth": "Fourth",
      "repeatMenu.custom.friday": "Friday",
      "repeatMenu.custom.monday": "Monday",
      "repeatMenu.custom.month": "Month",
      "repeatMenu.custom.months": "Months",
      "repeatMenu.custom.on": "On",
      "repeatMenu.custom.saturday": "Saturday",
      "repeatMenu.custom.second": "Second",
      "repeatMenu.custom.sunday": "Sunday",
      "repeatMenu.custom.third": "Third",
      "repeatMenu.custom.thursday": "Thursday",
      "repeatMenu.custom.title": "Repeats Every:",
      "repeatMenu.custom.tuesday": "Tuesday",
      "repeatMenu.custom.wednesday": "Wednesday",
      "repeatMenu.custom.week": "Week",
      "repeatMenu.custom.weeks": "Weeks",
      "repeatMenu.custom.year": "Year",
      "repeatMenu.custom.years": "Years",
      "repeatMenu.daily": "Daily",
      "repeatMenu.monthly": "Monthly",
      "repeatMenu.monthlyByWeekday": "Monthly on the",
      "repeatMenu.none": "None",
      "repeatMenu.weekdays": "Every Weekday(Mon-Fri)",
      "repeatMenu.weekly": "Weekly",
      "repeatMenu.yearly": "Yearly",
      "reportBuilder.modal.deleteConfirmation.header": "Hold up!",
      "reportBuilder.modal.deleteConfirmation.message": "If you leave now, your report will not be sent, and you will need to make a new one. Would you like to continue?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Yes, Delete This Report",
      "reportBuilder.modal.header.completionReport": "Completion Report",
      "reportBuilder.modal.header.employeeScorecard": "Employee Scorecard",
      "reportBuilder.whoGetsItPage.header": "Who gets it?",
      "reportBuilder.whoGetsItPage.message": "Enter one or more emails.",
      "reportBuilder.whoGetsItPage.input.label": "Send To:",
      "reportBuilder.dateRangePage.header": "Date Range",
      "reportBuilder.dateRangePage.message": "Include data from this date range...",
      "reportBuilder.dateRangePage.range.last24hours": "Last 24 Hours",
      "reportBuilder.dateRangePage.range.last7days": "Last 7 Days",
      "reportBuilder.dateRangePage.range.last30days": "Last 30 Days",
      "reportBuilder.dateRangePage.range.last90days": "Last 90 Days",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Last Full Month",
      "reportBuilder.dateRangePage.range.custom": "Custom Date Range... (see below)",
      "reportBuilder.dateRangePage.range.custom.firstDate": "First Date:",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Last Date:",
      "reportBuilder.employeeSelectionPage.header": "Whose Data",
      "reportBuilder.employeeSelectionPage.message": "Whose tasks should we include?",
      "reportBuilder.previewPage.details.listsIncluded": "Lists Included:",
      "reportBuilder.previewPage.details.recipients": "Recipients:",
      "reportBuilder.previewPage.emailNow": "Email Now",
      "reportBuilder.previewPage.radioGroup.header": "Show",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Add Comments",
      "reportBuilder.previewPage.radioGroup.option.lists": "Lists (recommended)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Lists and tasks",
      "reportBuilder.previewPage.radioGroup.option.notes": "Task notes",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Report Name",
      "reportBuilder.whichListPage.clearFilters": "Clear Filters",
      "reportBuilder.whichListsPage.header": "Which Lists?",
      "reportBuilder.whoseDataPage.header": "Whose Data?",
      "reportBuilder.filterSearchbar.byListName": "By List Name",
      "reportBuilder.filterSearchbar.byPerson": "By Person",
      "reportBuilder.toast.deleted": "Report Deleted!",
      "reportBuilder.toast.submitted": "Report Sent!",
      "reports": "Reports",
      "reports.comletionReport": "Completion Report",
      "reports.newCompletionReport": "New Completion Report",
      "reports.employeeScorecard": "Employee Scorecard",
      "reports.newEmployeeScorecard": "New Employee Scorecard",
      "required": "Required",
      "requirePhoto": "Require a Photo",
      "requirePhoto.makeOptional": "Make Optional",
      "requirePhoto.takePhoto": "Take Photo (required)",
      "rrule.on.weekly": "on",
      "rrule.on.yearly": "on",
      "rrule.weekdays": "(Mon-Fri)",
      "searchForLists": "Search for lists...",
      "searchForTasks": "Search for tasks...",
      "searchForTimeZone": "Search for time zone...",
      "searchInput.placeholder": "Search...",
      "select": "Select",
      "selected": "Selected",
      "selectAll": "Select All",
      "sendCode": "Send Verification Code",
      "settings": "Settings",
      "settings.darkMode": "Dark Mode",
      "settings.language": "Language",
      "settings.wifiOnly": "Use Wifi Only",
      "settings.wifiOnly.hint": "If you want the app to use your cell phone data, leave this option disabled. If you want to preserve your cell data, enable it.",
      "settings.notifications": "Notifications",
      "settings.notifications.enabled": "Enabled",
      "settings.notifications.enableDeviceLocationFirst": "Enable Device Location First",
      "settings.notifications.disabled": "Disabled",
      "settings.notifications.loading": "Loading...",
      "settings.notifications.unavailable": "N/A (Mobile Only)",
      "settings.locations": "Location",
      "settings.locations.appAccessToLocation": "App Access to Location",
      "settings.locations.enabled": "Enabled",
      "settings.locations.deviceLocation": "Device Location",
      "settings.locations.disabled": "Disabled",
      "settings.locations.loading": "Loading...",
      "settings.locations.goToAppSettings": "Go to App Settings",
      "settings.locations.goToPhoneSettings": "Go to Phone Settings",
      //      "settings.locations.unavailable": "N/A (Mobile Only)",
      "share": "Share",
      "shared": "Shared",
      "shareList": "Share List",
      "submit": "Submit",
      "syncIndicator.popover.text": "Your data is syncing. But don’t worry! You can keep working and we’ll update everything in the meantime.",
      "task": "Task",
      "task_one": "Task",
      "task_other": "Tasks",
      "tasks": "Tasks",
      "timeZone": "Time Zone",
      "today": "Today",
      "tomorrow": "Tomorrow",
      "toolbar.tab.home": "Home",
      "toolbar.tab.reports": "Reports",
      "toolbar.tab.settings": "Settings",
      "unarchive": "Unarchive",
      "undefinedList.name": "Undefined List",
      "update.title": "Level up!",
      "update.body": "An app update is available for you! We're always working to bring you the best stuff.",
      "Yesterday": "Yesterday",

      "reports.header.completion": "Completion",
      "reports.header.createdBy": "Report Created by",
      "reports.header.reportRunOn": "Report Run on",
      "reports.header.streak": "# of days in a row with no late tasks as of {{date}}",
      "reports.header.mystreak": "My Streak",
      "reports.config.onTime": "On Time",
      "reports.config.late": "Late",
      "reports.config.lateDays": "Late ({{days}} days)",
      "reports.config.missed": "Missed",
      "reports.config.na": "N/A",
      "reports.config.status.tasks": "Tasks",
      "reports.config.status.task": "Task",
      "reports.config.status.noTasks": "No Tasks",
      "reports.config.status.dueDate": "Due Date",
      "reports.config.status.completePct": "% Complete",
      "reports.config.status.complete": "Complete",
      "reports.config.status.completed": "Completed",
      "reports.config.status.completedBy": "Completed By",
      "reports.config.status.completion": "Completion",
      "reports.config.status.photos": "Photos",
      "reports.config.status.photos.before": "(Before)",
      "reports.config.status.photos.after": "(After)",
      "reports.config.status.photos.deficiency": "(Deficiency)",
      "reports.config.status.nearestAddress": "Nearest Address",
      "reports.config.status.assignedTo": "Assigned",
      "reports.config.status.comments": "Comments",
      "reports.config.status.notes": "Notes",
      "reportBuilder.previewPage.includeCompletedBy": "Include Completed By",
      "reportBuilder.previewPage.missedTasks": "Only Show Missed Tasks",
      "reports.config.noTasksFound": "No tasks found (in the date range provided).",
      "reports.config.status.assignedTasks": "Assigned Tasks",
      "timetracking.weeklyTotal": "Weekly Total",
      "timetracking.dailyTotal": "Daily Total",
      "timetracking.hours": "Hours",
      "timetracking.weekBreakdown": "Week Breakdown",
      "timetracking.startDate": "Start Date",
      "timetracking.startTime": "Start Time",
      "timetracking.endDate": "End Date",
      "timetracking.endTime": "End Time",
      "timetracking.clockedInAt": "Clocked In At",
      "timetracking.clockedOutAt": "Clocked Out At",
      "timetracking.clockIn": "Clock In",
      "timetracking.clockOut": "Clock Out",
      "timetracking.tab": "Time",
      "timetracking.wait": "Wait!",
      "timetracking.clockInConfirmation": "You have been clocked in for more than 12 hours, did you miss a punch and mean to clock in?",
      "timetracking.clockOutConfirmation": "You have been clocked in for more than 12 hours, did you miss a punch and mean to clock in?",
      "timetracking.clockMeIn": "Yes, Clock Me In",
      "timetracking.stayClockedOut": "No, Stay Clocked Out",
      "timetracking.clockMeOut": "No, I’m Clocking Out"
    }
  },
  es: {
    translation: {
      "addFirstList": "Toca el simbolo de \"+\" \n para agregar una lista",
      "addFirstTask": "Toca el simbolo de \"+\" \n para agregar una tarea",
      "addList": "Agregar Lista",
      "addTask": "Agregar Tarea",
      "after": "Después",
      "archive": "Archivar",
      "archived": "Archivadas",
      "assign": "Asignar",
      "assignList": "Asignar lista",
      "assignMenu.deleteConfirmation.header": "Sobrescribir asignaciones",
      "assignMenu.deleteConfirmation.message": "Cualquier asignación existente de esta lista se eliminará y luego se asignará a la persona que seleccionó. \n \n Nota: Las tareas completadas no se reasignarán.",
      "assignMenu.deselectUsers.assign": "Nadie (Desasignar)",
      "assignMenu.deselectUsers.share": "Nadie (No compartir)",
      "assignMenu.header.list.assign": "Asignar lista a:",
      "assignMenu.header.list.share": "Compartir lista con:",
      "assignMenu.header.list.shareOwner": "Compartir / Agregar Propietarios:",
      "assignMenu.header.task.assign_one": "Asignar tarea a:",
      "assignMenu.header.task.assign_other": "Asignar tareas a:",
      "assignTask": "Asignar Tarea",
      "assignTask.to": "Asignar tarea a:",
      "back": "Atrás",
      "before": "Antes",
      "button.acknowledge": "Aceptar",
      "button.later": "Más tarde",
      "button.update": "Actualizar",
      "button.illAddTasks": "Agregar Tareas",
      "button.addTasksForMe": "Agregar Tareas por Mí",
      "calendarView.clear": "Limpiar",
      "calendarView.repeat": "Repetir",
      "calendarView.time": "Hora",
      "cancel": "Cancelar",
      "change": "Cambiar",
      "complete": "Completar",
      "completeList": "Completar Lista",
      "completeTask": "Completar Tarea",
      "completeTasks": "Completar Tareas",
      "completed": "Completadas",
      "completionReport": "Reporte de Finalización",
      "confirmation.agreement.button.accept": "Aceptar",
      "confirmation.agreement.button.reject": "No Aceptar",
      "confirmation.agreement.header.default": "¡Bienvenido!",
      "confirmation.agreement.header.updated": "Actualizado",
      "confirmation.agreement.body.default": "Para usar Otuvy Frontline, deberá aceptar nuestra <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidad</a> y <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Términos de Servicio</a>.",
      "confirmation.agreement.body.updated": "Nuestros <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Términos de Servicio</a> y <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidad</a> han sido actualizados. Para continuar usando Otuvy Frontline, deberá aceptar estas actualizaciones.",
      "confirmation.delete.account.link": "Sí, eliminar mi cuenta.",
      "confirmation.delete.image.confirm": "Eliminar imagen",
      "confirmation.delete.image.header": "Eliminar Imagen",
      "confirmation.delete.image.message": "No podrá recuperar esta imagen una vez eliminada.",
      "confirmation.delete.list.confirm": "Eliminar la lista",
      "confirmation.delete.list.header": "¿Eliminar esta lista?",
      "confirmation.delete.list.message": "Todas sus tareas (y sus fotos, notas, etc.) también se eliminarán.",
      "confirmation.delete.profileImage.header": "¿Está seguro de que desea eliminar su imagen de perfil?",
      "confirmation.delete.task.confirm": "Eliminar tarea",
      "confirmation.delete.task.generic.confirm": "Eliminar",
      "confirmation.delete.task.generic.header": "Confirmar Eliminación",
      "confirmation.delete.task.generic.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.delete.task.header": "Eliminar Tarea",
      "confirmation.delete.task.message": "¿Está seguro? (Todas las fotos, notas, etc. también se eliminarán.)",
      "confirmation.dueDate.apply": "Sí, aplicar la fecha límite",
      "confirmation.dueDate.existingWillBeReplaced": "Cualquier fecha límite existente será reemplazada",
      "confirmation.dueDate.replace": "¿Reemplazar las fechas límite?",
      "contactSupport": "Contactar a Soporte",
      "date": "Fecha",
      "dayOfTheWeek": "Día de la Semana",
      "default.list.name": "Lista sin título",
      "default.task.name": "Tarea sin título",
      "deficiencies": "Deficiencias",
      "delete": "Eliminar",
      "deleteTasks": "Eliminar tareas",
      "deselectAll": "Deseleccionar Todo",
      "done": "Hecho",
      "dueDate": "Fecha Límite",
      "dueDateAndRepeat": "Fecha límite y repetición...",
      "duplicate": "Duplicar",
      "duplicateList": "Duplicar Lista",
      "duplicateTask": "Duplicar tarea",
      "duplicateTasks": "Duplicar tareas",
      "edit": "Editar",
      "email": "Correo electrónico",
      "employeeScorecard": "Evaluación del Empleado",
      "error.name.required": "El nombre es obligatorio",
      "error.phone.invalid": "El teléfono parece no ser válido",
      "error.phone.required": "El teléfono es obligatorio",
      "error.signin.incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "errorAlert.default.header": "Ups!",
      "errorAlert.default.message": "Nos encontramos con un error de nuestro lado. ¡Lo lamentamos! Si sucede varias veces, no dude en <a href='mailto:support@otuvy.com'>Ponerse en Contacto con el Soporte</a>.",
      "errorAlert.generateList.contentFilter.message": "Una o más de sus respuestas fue marcada como contenido inapropiado. Modifique sus respuestas e intente enviar nuevamente.<br/><br/>Si este error sucede varias veces, no dude en <a href='mailto=:support@otuvy.com'>Contactar Soporte</a>.",
      "errorAlert.generateList.generic.message": "Nos encontramos con un problema al crear la lista. ¡Lo lamentamos! Puede intentar enviar nuevamente.<br/><br/>Si sucede varias veces, no dude en <a href='mailto=:support@otuvy.com'>Contactar Soporte</a>.",
      "errorAlert.list.unavailable": "Parece que esto no está disponible para usted. Si tiene preguntas, consulte con su administrador o <a href='mailto=:support@otuvy.com'>Comuníquese con Soporte</a>.",
      "errorPage": "Hola, soy la Página de Error.",
      "errorPage.backIntoIt": "Retomemos.",
      "errorPage.header": "Hola, soy la Página de Error.",
      "errorPage.message": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "errorPage.sorry": "Lo sentimos, tuvimos un contratiempo de nuestra parte. No se preocupe, su trabajo debería guardarse automáticamente.",
      "favorites": "Favoritos",
      "feedback.list.completed": "¡Buen trabajo! Ha completado la lista.",
      "feedback.task.completed": "¡Buen trabajo! Tarea completada \"{{taskName}}\"",
      "incorrectOTP": "Incorrecto. Verifique dos veces el código de su correo electrónico e ingréselo nuevamente.",
      "instructions": "Instrucciones",
      "instructions.link.add": "+ Agregar un Enlace",
      "instructions.link.delete": "Eliminar Enlace",
      "instructions.link.delete.confirmation.text": "¿Está seguro de que desea eliminar este enlace?",
      "instructions.link.textToDisplay.label": "Texto a Mostrar (opcional)",
      "instructions.link.textToDisplay.placeholder": "10 Pasos para cortar del Césped",
      "instructions.link.save": "Guardar",
      "label.selectAllCompletedTasks": "Seleccionar todas las tareas completadas",
      "label.selectAllTasks": "Seleccionar todas las tareas",
      "label.task.instructions": "Instrucciones",
      "label.task.name": "Tarea",
      "label.task.notes": "Notas",
      "label.task.taskName": "Tarea",
      "link.deleteMyAccount": "Borrar mi cuenta",
      "link.faq": "Preguntas frecuentes",
      "link.feedback": "Dar sugerencias",
      "link.privacyPolicy": "Política de privacidad",
      "link.support": "Contactar a Soporte",
      "link.termsAndConditions": "Términos y condiciones",
      "link.termsOfService": "Términos de servicio",
      "link.voteOnFeatures": "Votar por Funcionalidades",
      "list": "Lista",
      "list_one": "Lista",
      "list_other": "Listas",
      "list.archive": "Archivar Lista",
      "list.unarchive": "Desarchivar Lista",
      "list.owner": "Propietario de la lista",
      "list.rename": "Cambiar Nombre",
      "listDetails.header": "Detalles de la Lista",
      "listName": "Nombre de la Lista",
      "lists": "Listas",
      "loading.generateList": "Creando tareas...",
      "loading.generic": "Espere...",
      "logout": "Cerrar sesión",
      "menu.action.hideArchivedLists": "Ocultar listas archivadas",
      "menu.action.showArchivedLists": "Mostrar listas archivadas",
      "menu.action.hideCompletedLists": "Ocultar listas completadas",
      "menu.action.hideCompletedTasks": "Ocultar tareas completadas",
      "menu.action.scanQRCodes": "Escanear códigos QR",
      "menu.action.select.lists": "Seleccionar listas",
      "menu.action.select.tasks": "Seleccionar tareas",
      "menu.action.share.list": "Compartir lista",
      "menu.action.shareListOrAddOwners": "Compartir listas o agregar propietarios",
      "menu.action.sort": "Ordenar por...",
      "menu.action.showCompletedLists": "Mostrar listas completadas",
      "menu.action.showCompletedTasks": "Mostrar tareas completadas",
      "menu.duedateAndRepeat": "Fecha límite y repetición",
      "menu.header.sort": "Ordenar por...",
      "menu.helpAndAbout": "Ayuda y Acerca de",
      "modal.generateList.header": "Preguntas",
      "modal.generateList.question1.accessibilityLabel": "Nombre de la Lista*",
      "modal.generateList.question1.label": "Comencemos con tu lista",
      "modal.generateList.question1.placeholder": "Nombre de la Lista, etc.",
      "modal.generateList.question2.label": "¿Qué tipo de tareas?",
      "modal.generateList.question2.placeholder": "Ej. Limpieza, Reparación, Instalación, etc.",
      "modal.generateList.question3.label": "¿Cuál es el objetivo principal de la lista?",
      "modal.generateList.question3.placeholder": "Dar tareas detalladas para desinfectar...",
      "modal.generateList.button.accept": "Agregar Tareas por Mí",
      "multiSelectMenu.deselect.archived": "Deseleccionar Todo Archivado",
      "multiSelectMenu.deselect.completed": "Deseleccionar Todo Completado",
      "multiSelectMenu.deselect.incomplete": "Deseleccionar Todo Incompleto",
      "multiSelectMenu.dueDateAndRepeat": "Fecha límite / Repetir",
      "multiSelectMenu.photoRequired": "Requerir Foto",
      "multiSelectMenu.photoRequired.makeOptional": "Desactivar Foto",
      "multiSelectMenu.select.archived": "Seleccionar Todo Archivado",
      "multiSelectMenu.select.completed": "Seleccionar Todo Completado",
      "multiSelectMenu.select.incomplete": "Seleccionar Todo Incompleto",
      "name": "Nombre",
      "newCodeRequest": "Enviarme un nuevo código",
      "newVerificationCodeSent.header": "Aún esperando un código?",
      "newVerificationCodeSent.message": "Use este tiempo para meditar o visitarnos en <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> para obtener más información sobre nosotros. También puede comunicarse con su administrador de cuentas para solucionar el problema.",
      "none": "Ninguno",
      "notes": "Notas",
      "notifications.notification.inApp": "Notificaciones",
      "notifications.listDone": "Listas Completadas",
      "notifications.tasksGetAssignedToMe": "Tareas Asignadas",
      "notifications.listsGetAssignedToMe": "Listas Asignadas",
      "notifications.pastDueTasks": "Tareas vencidas",
      "notifications.pastDueTasks.assignedToMe": "Asignado a mí",
      "notifications.pastDueTasks.oneOffs": "Uno-fuera",
      "notifications.pastDueTasks.repeating": "Repitiendo",
      "notifications.pastDueTasks.iveAssigned": "He asignado",
      "notifications.pastDueTasks.onSharedList": "En una lista compartida",
      "offline": "Sin conexión",
      "offlineIndicator.popover.text": "Tu teléfono está sin conexión. ¡Pero no te preocupes! Puede seguir trabajando y actualizaremos todo cuando vuelva a estar en línea.",
      "offlineNoticeHeader": "Sin internet \t(",
      "offlineNoticeMessage": "Deberíamos reconectarnos pronto. Para saber si no es solo nuestra aplicación, puede... <br/><br/><ul><li>Verificar la configuración de su teléfono</li><li>Verificar otras aplicaciones</li><li>Verificar la intensidad de la señal wifi</li></ul><br/>Y no se preocupe, ¡guardamos su trabajo a medida que avanzamos!",
      "option.sort.activity": "Actividad Reciente",
      "option.sort.assignedTo": "Asignado a",
      "option.sort.created": "Fecha de Creación",
      "option.sort.due": "Fecha límite",
      "option.sort.name": "Nombre",
      "otpCode": "Código",
      "otpPrompt": "Le enviaremos un código a su correo electrónico para que pueda comenzar a usar Otuvy Frontline",
      "otpSent": "Hemos enviado un código de verificación a su correo electrónico. Ingréselo a continuación.",
      "owner": "Propietario",
      "preview": "Vista previa",
      "phone": "Teléfono",
      "picturesAbbreviated": "Fotos",
      "placeholder.email": "maria.sanchez@ejemplo.com",
      "placeholder.generic": "Tocar para ingresar texto",
      "placeholder.name": "María Sánchez",
      "placeholder.otp": "Su código de verificación",
      "product.version": "v.",
      "registrationGreeting": "¡Bienvenido! Terminemos de configurar su cuenta...",
      "remove": "Eliminar",
      "repeat": "Repetir",
      "repeatMenu.custom": "Personalizado",
      "repeatMenu.custom.day": "Día",
      "repeatMenu.custom.days": "Días",
      "repeatMenu.custom.fifth": "Quinto",
      "repeatMenu.custom.first": "Primer",
      "repeatMenu.custom.fourth": "Cuarto",
      "repeatMenu.custom.friday": "Viernes",
      "repeatMenu.custom.monday": "Lunes",
      "repeatMenu.custom.month": "Mes",
      "repeatMenu.custom.months": "Meses",
      "repeatMenu.custom.on": "El",
      "repeatMenu.custom.saturday": "Sábado",
      "repeatMenu.custom.second": "Segundo",
      "repeatMenu.custom.sunday": "Domingo",
      "repeatMenu.custom.third": "Tercero",
      "repeatMenu.custom.thursday": "Jueves",
      "repeatMenu.custom.title": "Repetir cada:",
      "repeatMenu.custom.tuesday": "Martes",
      "repeatMenu.custom.wednesday": "Miércoles",
      "repeatMenu.custom.week": "Semana",
      "repeatMenu.custom.weeks": "Semanas",
      "repeatMenu.custom.year": "Año",
      "repeatMenu.custom.years": "Años",
      "repeatMenu.daily": "Diario",
      "repeatMenu.monthly": "Mensualmente",
      "repeatMenu.monthlyByWeekday": "Mensual el",
      "repeatMenu.none": "Ninguno",
      "repeatMenu.weekdays": "Cada dia Laborable(Lun-Vie)",
      "repeatMenu.weekly": "Semanalmente",
      "repeatMenu.yearly": "Anualmente",
      "reportBuilder.modal.deleteConfirmation.header": "¡Espera!",
      "reportBuilder.modal.deleteConfirmation.message": "Si te vas ahora, tu reporte no se enviará y tendrás que hacer uno nuevo. ¿Te gustaría continuar?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Sí, Eliminar Este Reporte",
      "reportBuilder.modal.header.completionReport": "Reporte de Finalización",
      "reportBuilder.modal.header.employeeScorecard": "Evaluación del Empleado",
      "reportBuilder.whoGetsItPage.header": "¿Quién lo recibe?",
      "reportBuilder.whoGetsItPage.message": "Ingrese uno o más correos electrónicos.",
      "reportBuilder.whoGetsItPage.input.label": "Enviar a:",
      "reportBuilder.dateRangePage.header": "Rango de Fechas",
      "reportBuilder.dateRangePage.message": "Incluir datos de este rango de fechas...",
      "reportBuilder.dateRangePage.range.last24hours": "Últimas 24 horas",
      "reportBuilder.dateRangePage.range.last7days": "Últimos 7 días",
      "reportBuilder.dateRangePage.range.last30days": "Últimos 30 días",
      "reportBuilder.dateRangePage.range.last90days": "Últimos 90 días",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Último mes completo",
      "reportBuilder.dateRangePage.range.custom": "Rango de fechas personalizado... (ver abajo)",
      "reportBuilder.dateRangePage.range.custom.firstDate": "Primera Fecha:",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Última Fecha:",
      "reportBuilder.employeeSelectionPage.header": "De quién son los datos",
      "reportBuilder.employeeSelectionPage.message": "De quiénes deberíamos incluir las tareas?",
      "reportBuilder.previewPage.details.listsIncluded": "Listas Incluidas:",
      "reportBuilder.previewPage.details.recipients": "Destinatarios:",
      "reportBuilder.previewPage.emailNow": "Enviar Ahora",
      "reportBuilder.previewPage.radioGroup.header": "Mostrar",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Agregar Comentarios",
      "reportBuilder.previewPage.radioGroup.option.lists": "Listas (recomendado)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Listas y tareas",
      "reportBuilder.previewPage.radioGroup.option.notes": "Notas de tareas",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Nombre del Reporte",
      "reportBuilder.whichListPage.clearFilters": "Borrar Filtros",
      "reportBuilder.whichListsPage.header": "¿Qué listas?",
      "reportBuilder.filterSearchbar.byListName": "Por Nombre de Lista",
      "reportBuilder.filterSearchbar.byPerson": "Por Persona",
      "reportBuilder.toast.deleted": "¡Reporte Eliminado!",
      "reportBuilder.toast.submitted": "¡Reporte Enviado!",
      "reports": "Reportes",
      "reports.comletionReport": "Reporte de Finalización",
      "reports.newCompletionReport": "Nuevo Reporte de Finalización",
      "reports.employeeScorecard": "Evaluación del Empleado",
      "reports.newEmployeeScorecard": "Nueva Evaluación del Empleado",
      "required": "Requerido",
      "requirePhoto": "Requerir una Foto",
      "requirePhoto.makeOptional": "Hacer Opcional",
      "requirePhoto.takePhoto": "Tomar Foto (requerido)",
      "rrule.on.weekly": "los",
      "rrule.on.yearly": "el",
      "rrule.weekdays": "(Lun-Vie)",
      "searchForLists": "Buscar listas...",
      "searchForTasks": "Buscar tareas...",
      "searchForTimeZone": "Buscar zona horaria...",
      "searchInput.placeholder": "Buscar",
      "select": "Seleccionar",
      "selected_one": "Seleccionado",
      "selected_other": "Seleccionados",
      "selectAll": "Seleccionar Todo",
      "sendCode": "Enviar Código de Verificación",
      "settings": "Ajustes",
      "settings.darkMode": "Modo Oscuro",
      "settings.language": "Idioma",
      "settings.notifications": "Notificaciones",
      "settings.wifiOnly": "Usar Solo Wifi",
      "settings.wifiOnly.hint": "Si desea que la aplicación use los datos de su teléfono celular, deje esta opción desactivada. Si desea conservar los datos de su celular, actívela.",
      "settings.notifications.enabled": "Activado",
      "settings.notifications.enableDeviceLocationFirst": "Habilitar Ubicación del Dispositivo Primero",
      "settings.notifications.disabled": "Desactivado",
      "settings.notifications.loading": "Cargando...",
      "settings.notifications.unavailable": "N/A (Solo en Dispositivos Móviles)",
      "settings.locations": "Ubicación",
      "settings.locations.appAccessToLocation": "Acceso de la Aplicación a la Ubicación",
      "settings.locations.enabled": "Activado",
      "settings.locations.deviceLocation": "Ubicación del Dispositivo",
      "settings.locations.disabled": "Desactivado",
      "settings.locations.loading": "Cargando...",
      "settings.locations.goToAppSettings": "Configuración de la Aplicación",
      "settings.locations.goToPhoneSettings": "Configuración del Dispositivo",
      "share": "Compartir",
      "shared": "Compartida",
      "shareList": "Compartir Lista",
      "submit": "Enviar",
      "task": "Tarea",
      "task_one": "Tarea",
      "task_other": "Tareas",
      "tasks": "Tareas",
      "timeZone": "Hora Local",
      "today": "Hoy",
      "tomorrow": "Mañana",
      "toolbar.tab.home": "Inicio",
      "toolbar.tab.reports": "Reportes",
      "toolbar.tab.settings": "Ajustes",
      "unarchive": "Desarchivar",
      "undefinedList.name": "Undefined List",
      "update.title": "¡Actualiza!",
      "update.body": "¡Hay una actualización de la aplicación disponible para ti! Siempre estamos trabajando para brindarte lo mejor.",
      "Yesterday": "Ayer",
      
      "reports.header.completion": "Finalización",
      "reports.header.createdBy": "Informe creado por",
      "reports.header.reportRunOn": "Informe ejecutado el",
      "reports.header.streak": "# de días seguidos sin tareas atrasadas hasta el {{date}}",
      "reports.header.mystreak": "Mi Racha",
      "reports.config.onTime": "A Tiempo",
      "reports.config.late": "Tarde",
      "reports.config.lateDays": "Tarde ({{days}} días)",
      "reports.config.missed": "Perdido",
      "reports.config.na": "N/A",
      "reports.config.status.tasks": "Tareas",
      "reports.config.status.task": "Tarea",
      "reports.config.status.noTasks": "No hay tareas",
      "reports.config.status.dueDate": "Fecha de Vencimiento",
      "reports.config.status.completePct": "% Completado",
      "reports.config.status.complete": "Completo",
      "reports.config.status.completed": "Completado",
      "reports.config.status.completedBy": "Completado por",
      "reports.config.status.completion": "Finalización",
      "reports.config.status.photos": "Fotos",
      "reports.config.status.photos.before": "(Antes)",
      "reports.config.status.photos.after": "(Después)",
      "reports.config.status.photos.deficiency": "(Deficiencia)",
      "reports.config.status.nearestAddress": "Dirección más cercana",
      "reports.config.status.assignedTo": "Asignado a",
      "reports.config.status.comments": "Comentarios",
      "reports.config.status.notes": "Notas",
      "reportBuilder.previewPage.includeCompletedBy": "Incluir Completado por",
      "reportBuilder.previewPage.missedTasks": "Mostrar solo tareas perdidas",
      "reports.config.noTasksFound": "No se encontraron tareas (en el rango de fechas proporcionado).",
      "reports.config.status.assignedTasks": "Tareas Asignadas",
      "timetracking.weeklyTotal": "Total Semanal",
      "timetracking.dailyTotal": "Total Diario",
      "timetracking.hours": "Horas",
      "timetracking.weekBreakdown": "Desglose Semanal",
      "timetracking.startDate": "Fecha de Inicio",
      "timetracking.startTime": "Hora de Inicio",
      "timetracking.endDate": "Fecha de Finalización",
      "timetracking.endTime": "Hora de Finalización",
      "timetracking.clockedInAt": "Fichado a las",
      "timetracking.clockedOutAt": "Salida registrada a las",
      "timetracking.clockIn": "Registrar Entrada",
      "timetracking.clockOut": "Registrar Salida",
      "timetracking.tab": "Horas",
      "timetracking.wait": "¡Espera!",
      "timetracking.clockInConfirmation": "Has estado registrado por más de 12 horas, ¿olvidaste fichar y querías registrar la entrada?",
      "timetracking.clockOutConfirmation": "Has estado registrado por más de 12 horas, ¿olvidaste fichar y querías registrar la entrada?",
      "timetracking.clockMeIn": "Sí, Regístrame",
      "timetracking.stayClockedOut": "No, Mantenerme Fuera",
      "timetracking.clockMeOut": "No, Estoy Registrando mi Salida",
    }
  },
  "fr": {
    "translation": {
      "addFirstList": "Touchez le \"+\" \n pour ajouter une liste",
      "addFirstTask": "Touchez le \"+\" \n pour ajouter une tâche",
      "addList": "Ajouter une liste",
      "addTask": "Ajouter une tâche",
      "after": "Après",
      "alert.offline.photo.header": "Actuellement Hors Ligne",
      "alert.offline.photo.message": "Veuillez vous reconnecter pour télécharger les images.",
      "archive": "Archiver",
      "archived": "Archivé",
      "assign": "Assigner",
      "assignList": "Assigner une liste",
      "assignMenu.deleteConfirmation.header": "Écraser les attributions",
      "assignMenu.deleteConfirmation.message": "Toutes les attributions existantes de cette liste seront supprimées, puis attribuées à la personne que vous avez sélectionnée. \n \n Remarque : Les tâches terminées ne seront pas réattribuées.",
      "assignMenu.deselectUsers.assign": "Personne (Désassigner)",
      "assignMenu.deselectUsers.share": "Personne (Ne pas partager)",
      "assignMenu.header.list.assign": "Assigner la liste à :",
      "assignMenu.header.list.share": "Partager la liste avec :",
      "assignMenu.header.list.shareOwner": "Partager / Ajouter des propriétaires :",
      "assignMenu.header.task.assign_one": "Assigner la tâche à :",
      "assignMenu.header.task.assign_other": "Assigner les tâches à :",
      "assignTask": "Assigner une tâche",
      "assignTask.to": "Assigner la tâche à :",
      "banner.missedRequiredUpdate.main": "Une mise à jour est requise",
      "banner.writeAccessRestricted.info.body.nonPayment.1": "En raison de retards de paiement, l'accès de votre entreprise a été limité.",
      "banner.writeAccessRestricted.info.body.nonPayment.2": "Veuillez contacter votre administrateur pour mettre à jour les informations de paiement afin de continuer à bénéficier de l'accès.",
      "banner.writeAccessRestricted.info.header": "Accès limité",
      "banner.writeAccessRestricted.main": "L'accès est limité",
      "back": "Retour",
      "before": "Avant",
      "button.acknowledge": "OK",
      "button.later": "Plus tard",
      "button.update": "Mettre à jour",
      "button.illAddTasks": "Je vais ajouter des tâches",
      "button.addTasksForMe": "Ajouter des tâches pour moi",
      "calendarView.clear": "Effacer",
      "calendarView.repeat": "Répéter",
      "calendarView.time": "Heure",
      "cancel": "Annuler",
      "change": "Modifier",
      "complete": "Terminer",
      "completeList": "Terminer la liste",
      "completeTask": "Terminer la tâche",
      "completeTasks": "Terminer les tâches",
      "completed": "Terminé",
      "completionReport": "Rapport d'achèvement",
      "confirmation.agreement.button.accept": "Accepter",
      "confirmation.agreement.button.reject": "Ne pas accepter",
      "confirmation.agreement.header.default": "Bienvenue !",
      "confirmation.agreement.header.updated": "Mis à jour",
      "confirmation.agreement.body.default": "Pour utiliser Otuvy Frontline, vous devez accepter notre <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Politique de confidentialité</a> et nos <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Conditions d'utilisation</a>.",
      "confirmation.agreement.body.updated": "Nos <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Conditions d'utilisation</a> et notre <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Politique de confidentialité</a> ont été mises à jour. Pour continuer à utiliser Otuvy Frontline, vous devez accepter ces mises à jour.",
      "confirmation.delete.account.link": "Oui, supprimer mon compte",
      "confirmation.delete.image.confirm": "Supprimer l'image",
      "confirmation.delete.image.header": "Supprimer l'image",
      "confirmation.delete.image.message": "Vous ne pourrez pas récupérer cette image une fois supprimée.",
      "confirmation.delete.list.confirm": "Supprimer la liste",
      "confirmation.delete.list.header": "Supprimer cette liste ?",
      "confirmation.delete.list.message": "Toutes ses tâches (et leurs photos, notes, etc.) seront également supprimées.",
      "confirmation.delete.profileImage.header": "Êtes-vous sûr de vouloir supprimer votre image de profil ?",
      "confirmation.delete.task.confirm": "Supprimer la tâche",
      "confirmation.delete.task.generic.confirm": "Supprimer",
      "confirmation.delete.task.generic.header": "Confirmer la suppression",
      "confirmation.delete.task.generic.message": "Êtes-vous sûr ? (Toutes les photos, notes, etc. seront également supprimées.)",
      "confirmation.delete.task.header": "Supprimer la tâche",
      "confirmation.delete.task.message": "Êtes-vous sûr ? (Toutes les photos, notes, etc. seront également supprimées.)",
      "confirmation.dueDate.apply": "Oui, appliquer la date d'échéance",
      "confirmation.dueDate.existingWillBeReplaced": "Toute date d'échéance existante sera remplacée",
      "confirmation.dueDate.replace": "Remplacer les dates d'échéance ?",
      "contactSupport": "Contacter le support",
      "date": "Date",
      "dayOfTheWeek": "Jour de la semaine",
      "default.list.name": "Liste sans titre",
      "default.task.name": "Tâche sans titre",
      "deficiencies": "Déficiences",
      "delete": "Supprimer",
      "deleteTasks": "Supprimer les tâches",
      "deselect": "Désélectionner",
      "deselectAll": "Tout désélectionner",
      "done": "Terminé",
      "dueDate": "Date d'échéance",
      "dueDateAndRepeat": "Date d'échéance et répétition...",
      "duplicate": "Dupliquer",
      "duplicateList": "Dupliquer la liste",
      "duplicateTask": "Dupliquer la tâche",
      "duplicateTasks": "Dupliquer les tâches",
      "edit": "Modifier",
      "email": "Email",
      "employeeScorecard": "Fiche d'évaluation de l'employé",
      "error.name.required": "Le nom est requis",
      "error.phone.invalid": "Le numéro de téléphone semble invalide",
      "error.phone.required": "Le numéro de téléphone est requis",
      "error.signin.incorrectOTP": "Incorrect. Veuillez vérifier le code de votre email et réessayer.",
      "errorAlert.default.header": "Oups !",
      "errorAlert.default.message": "Nous avons rencontré une erreur de notre côté. Désolé ! Si cela se produit plusieurs fois, n'hésitez pas à <a href='mailto:support@otuvy.com'>contacter le support</a>.",
      "errorAlert.generateList.contentFilter.message": "Une ou plusieurs de vos réponses ont été signalées comme contenant un contenu inapproprié. Veuillez modifier vos réponses et réessayer.<br/><br/>Si cette erreur se produit plusieurs fois, n'hésitez pas à <a href='mailto:support@otuvy.com'>contacter le support</a>.",
      "errorAlert.generateList.generic.message": "Nous avons rencontré un problème lors de la création de la liste. Désolé ! Vous pouvez réessayer.<br/><br/>Si cela se produit plusieurs fois, n'hésitez pas à <a href='mailto:support@otuvy.com'>contacter le support</a>.",
      "errorAlert.list.unavailable": "Il semble que ceci ne soit pas disponible pour vous. Si vous avez des questions, veuillez vérifier auprès de votre administrateur ou <a href='mailto:support@otuvy.com'>contacter le support</a>.",
      "errorPage": "Bonjour, je suis la page d'erreur.",
      "errorPage.backIntoIt": "Reprenons où nous en étions.",
      "errorPage.header": "Bonjour, je suis la page d'erreur.",
      "errorPage.message": "Désolé, nous avons eu un souci de notre côté. Ne vous inquiétez pas, votre travail devrait être sauvegardé automatiquement.",
      "errorPage.unavailableList.message": "'Il semble que cette liste ne soit plus disponible'.",
      "errorPage.sorry": "Désolé, nous avons eu un souci de notre côté. Ne vous inquiétez pas, votre travail devrait être sauvegardé automatiquement.",
      "favorites": "Favoris",
      "feedback.list.completed": "Beau travail ! Vous avez complété la liste.",
      "feedback.task.completed": "Beau travail ! Tâche terminée \"{{taskName}}\"",
      "generatedTask.instructions.duration": "({{duration}} min)",
      "incorrectOTP": "Incorrect. Veuillez vérifier le code de votre email et le saisir à nouveau.",
      "instructions": "Instructions",
      "instructions.link.add": "+ Ajouter un lien",
      "instructions.link.delete": "Supprimer le lien",
      "instructions.link.delete.confirmation.text": "Êtes-vous sûr de vouloir supprimer ce lien ?",
      "instructions.link.textToDisplay.label": "Texte à afficher (optionnel)",
      "instructions.link.textToDisplay.placeholder": "ex. 10 étapes pour tailler la pelouse",
      "instructions.link.save": "Enregistrer le lien",
      "label.selectAllCompletedTasks": "Sélectionner toutes les tâches terminées",
      "label.selectAllTasks": "Sélectionner toutes les tâches",
      "label.task.instructions": "Instructions",
      "label.task.name": "Tâche",
      "label.task.notes": "Notes",
      "label.task.taskName": "Tâche",
      "link.deleteMyAccount": "Supprimer mon compte",
      "link.faq": "Questions fréquemment posées",
      "link.feedback": "Donner un avis",
      "link.privacyPolicy": "Politique de confidentialité",
      "link.support": "Contacter le support",
      "link.termsAndConditions": "Conditions générales",
      "link.termsOfService": "Conditions d'utilisation",
      "link.voteOnFeatures": "Voter sur les fonctionnalités",
      "list": "Liste",
      "list_one": "Liste",
      "list_other": "Listes",
      "list.archive": "Archiver la liste",
      "list.unarchive": "Désarchiver la liste",
      "list.owner": "Propriétaire de la liste",
      "list.rename": "Renommer la liste",
      "listDetails.header": "Détails de la liste",
      "listName": "Nom de la liste",
      "lists": "Listes",
      "loading.generateList": "Création des tâches...",
      "loading.generic": "Veuillez patienter...",
      "logout": "Se déconnecter",
      "menu.action.hideArchivedLists": "Masquer les listes archivées",
      "menu.action.showArchivedLists": "Afficher les listes archivées",
      "menu.action.hideCompletedLists": "Masquer les listes terminées",
      "menu.action.hideCompletedTasks": "Masquer les tâches terminées",
      "menu.action.scanQRCodes": "Scanner les codes QR",
      "menu.action.select.lists": "Sélectionner des listes",
      "menu.action.select.tasks": "Sélectionner des tâches",
      "menu.action.share.list": "Partager la liste",
      "menu.action.shareListOrAddOwners": "Partager des listes ou ajouter des propriétaires",
      "menu.action.showCompletedLists": "Afficher les listes terminées",
      "menu.action.showCompletedTasks": "Afficher les tâches terminées",
      "menu.action.sort": "Trier par...",
      "menu.duedateAndRepeat": "Date d'échéance et répétition",
      "menu.header.sort": "Trier par...",
      "menu.helpAndAbout": "Aide & À propos",
      "modal.generateList.header": "Questions",
      "modal.generateList.question1.accessibilityLabel": "Nom de la liste*",
      "modal.generateList.question1.label": "Commençons par votre liste",
      "modal.generateList.question1.placeholder": "Nom de la liste, etc.",
      "modal.generateList.question2.label": "Quel type de tâches ?",
      "modal.generateList.question2.placeholder": "ex. Nettoyage, Réparation, Installation, etc.",
      "modal.generateList.question3.label": "Quel est l'objectif principal de la liste ?",
      "modal.generateList.question3.placeholder": "Fournir des tâches détaillées pour désinfecter la cuisine.",
      "modal.generateList.button.accept": "Ajouter des tâches pour moi",
      "multiSelectMenu.deselect.archived": "Désélectionner tout archivé",
      "multiSelectMenu.deselect.completed": "Désélectionner tout terminé",
      "multiSelectMenu.deselect.incomplete": "Désélectionner tout inachevé",
      "multiSelectMenu.dueDateAndRepeat": "Date d'échéance / Répétition",
      "multiSelectMenu.photoRequired": "Exiger une photo",
      "multiSelectMenu.photoRequired.makeOptional": "Désactiver la photo",
      "multiSelectMenu.select.archived": "Sélectionner tout archivé",
      "multiSelectMenu.select.completed": "Sélectionner tout terminé",
      "multiSelectMenu.select.incomplete": "Sélectionner tout inachevé",
      "name": "Nom",
      "newCodeRequest": "Envoyez-moi un nouveau code",
      "newVerificationCodeSent.header": "Vous attendez encore un code ?",
      "newVerificationCodeSent.message": "Profitez de ce temps pour méditer ou visitez <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> pour en savoir plus sur nous. Vous pouvez également contacter l'administrateur de votre compte pour résoudre le problème.",
      "none": "Aucun",
      "notes": "Notes",
      "notifications.notification.inApp": "Notifications In-App",
      "notifications.notification.gotoOSSettings.label": "Paramètres du téléphone",
      "notifications.listDone": "Liste terminée",
      "notifications.tasksGetAssignedToMe": "Des tâches me sont attribuées",
      "notifications.listsGetAssignedToMe": "Devenir propriétaire de la liste",
      "notifications.pastDueTasks": "Tâches en retard",
      "notifications.pastDueTasks.assignedToMe": "Attribuées à moi",
      "notifications.pastDueTasks.oneOffs": "Ponctuelles",
      "notifications.pastDueTasks.repeating": "répétitives",
      "notifications.pastDueTasks.onSharedList": "Sur une liste partagée",
      "popover.generateList.offline": "Vous devez être en ligne pour ajouter automatiquement des tâches.",
      "offline": "Hors ligne",
      "offlineIndicator.popover.text": "Votre téléphone est hors ligne. Mais ne vous inquiétez pas ! Vous pouvez continuer à travailler et nous mettrons tout à jour lorsque vous serez de nouveau en ligne.",
      "offlineNoticeHeader": "Pas d'internet \t(",
      "offlineNoticeMessage": "Nous devrions nous reconnecter bientôt. Pour savoir si ce n'est pas uniquement notre application, vous pouvez... <br/> <br/> <ul><li>Vérifier les paramètres de votre téléphone</li><li>Vérifier d'autres applications</li><li>Vérifier la force du signal Wi-Fi</li></ul><br/>Et ne vous inquiétez pas, nous sauvegardons votre travail au fur et à mesure !",
      "option.sort.activity": "Activité récente",
      "option.sort.assignedTo": "Attribué à",
      "option.sort.created": "Date de création",
      "option.sort.due": "Date d'échéance",
      "option.sort.name": "Nom",
      "otpCode": "Code",
      "otpPrompt": "Nous enverrons un code à votre email pour commencer à utiliser Otuvy Frontline",
      "otpSent": "Nous avons envoyé un code de vérification à votre email. Veuillez le saisir ci-dessous.",
      "owner": "Propriétaire",
      "preview": "Aperçu",
      "phone": "Téléphone",
      "picturesAbbreviated": "Photos",
      "placeholder.email": "jane.doe@exemple.com",
      "placeholder.generic": "Touchez pour entrer du texte",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Votre code de vérification",
      "product.version": "v.",
      "registrationGreeting": "Bienvenue ! Terminons la configuration de votre compte...",
      "remove": "Retirer",
      "repeat": "Répéter",
      "repeatMenu.custom": "Personnalisé",
      "repeatMenu.custom.day": "Jour",
      "repeatMenu.custom.days": "Jours",
      "repeatMenu.custom.fifth": "Cinquième",
      "repeatMenu.custom.first": "Premier",
      "repeatMenu.custom.fourth": "Quatrième",
      "repeatMenu.custom.friday": "Vendredi",
      "repeatMenu.custom.monday": "Lundi",
      "repeatMenu.custom.month": "Mois",
      "repeatMenu.custom.months": "Mois",
      "repeatMenu.custom.on": "Le",
      "repeatMenu.custom.saturday": "Samedi",
      "repeatMenu.custom.second": "Deuxième",
      "repeatMenu.custom.sunday": "Dimanche",
      "repeatMenu.custom.third": "Troisième",
      "repeatMenu.custom.thursday": "Jeudi",
      "repeatMenu.custom.title": "Répète tous les :",
      "repeatMenu.custom.tuesday": "Mardi",
      "repeatMenu.custom.wednesday": "Mercredi",
      "repeatMenu.custom.week": "Semaine",
      "repeatMenu.custom.weeks": "Semaines",
      "repeatMenu.custom.year": "An",
      "repeatMenu.custom.years": "Ans",
      "repeatMenu.daily": "Quotidien",
      "repeatMenu.monthly": "Mensuel",
      "repeatMenu.monthlyByWeekday": "Mensuel le",
      "repeatMenu.none": "Aucun",
      "repeatMenu.weekdays": "Chaque jour de la semaine (lun-ven)",
      "repeatMenu.weekly": "Hebdomadaire",
      "repeatMenu.yearly": "Annuel",
      "reportBuilder.modal.deleteConfirmation.header": "Attendez !",
      "reportBuilder.modal.deleteConfirmation.message": "Si vous partez maintenant, votre rapport ne sera pas envoyé, et vous devrez en créer un nouveau. Voulez-vous continuer ?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Oui, supprimer ce rapport",
      "reportBuilder.modal.header.completionReport": "Rapport d'achèvement",
      "reportBuilder.modal.header.employeeScorecard": "Fiche d'évaluation de l'employé",
      "reportBuilder.whoGetsItPage.header": "Qui le reçoit ?",
      "reportBuilder.whoGetsItPage.message": "Entrez une ou plusieurs adresses email.",
      "reportBuilder.whoGetsItPage.input.label": "Envoyer à :",
      "reportBuilder.dateRangePage.header": "Plage de dates",
      "reportBuilder.dateRangePage.message": "Inclure les données de cette plage de dates...",
      "reportBuilder.dateRangePage.range.last24hours": "Dernières 24 heures",
      "reportBuilder.dateRangePage.range.last7days": "7 derniers jours",
      "reportBuilder.dateRangePage.range.last30days": "30 derniers jours",
      "reportBuilder.dateRangePage.range.last90days": "90 derniers jours",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Dernier mois complet",
      "reportBuilder.dateRangePage.range.custom": "Plage de dates personnalisée... (voir ci-dessous)",
      "reportBuilder.dateRangePage.range.custom.firstDate": "Première date :",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Dernière date :",
      "reportBuilder.employeeSelectionPage.header": "Données de qui",
      "reportBuilder.employeeSelectionPage.message": "De qui doit-on inclure les tâches ?",
      "reportBuilder.previewPage.details.listsIncluded": "Listes incluses :",
      "reportBuilder.previewPage.details.recipients": "Destinataires :",
      "reportBuilder.previewPage.emailNow": "Envoyer par email maintenant",
      "reportBuilder.previewPage.radioGroup.header": "Afficher",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Ajouter des commentaires",
      "reportBuilder.previewPage.radioGroup.option.lists": "Listes (recommandé)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Listes et tâches",
      "reportBuilder.previewPage.radioGroup.option.notes": "Notes des tâches",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Nom du rapport",
      "reportBuilder.whichListPage.clearFilters": "Effacer les filtres",
      "reportBuilder.whichListsPage.header": "Quelles listes ?",
      "reportBuilder.whoseDataPage.header": "Les données de qui ?",
      "reportBuilder.filterSearchbar.byListName": "Par nom de liste",
      "reportBuilder.filterSearchbar.byPerson": "Par personne",
      "reportBuilder.toast.deleted": "Rapport supprimé !",
      "reportBuilder.toast.submitted": "Rapport envoyé !",
      "reports": "Rapports",
      "reports.comletionReport": "Rapport d'achèvement",
      "reports.newCompletionReport": "Nouveau rapport d'achèvement",
      "reports.employeeScorecard": "Fiche d'évaluation de l'employé",
      "reports.newEmployeeScorecard": "Nouvelle fiche d'évaluation de l'employé",
      "required": "Requis",
      "requirePhoto": "Exiger une photo",
      "requirePhoto.makeOptional": "Rendre optionnel",
      "requirePhoto.takePhoto": "Prendre une photo (requis)",
      "rrule.on.weekly": "le",
      "rrule.on.yearly": "le",
      "rrule.weekdays": "(Lun-Ven)",
      "searchForLists": "Rechercher des listes...",
      "searchForTasks": "Rechercher des tâches...",
      "searchForTimeZone": "Rechercher un fuseau horaire...",
      "searchInput.placeholder": "Rechercher...",
      "select": "Sélectionner",
      "selected": "Sélectionné",
      "selectAll": "Tout sélectionner",
      "sendCode": "Envoyer le code de vérification",
      "settings": "Paramètres",
      "settings.darkMode": "Mode sombre",
      "settings.language": "Langue",
      "settings.wifiOnly": "Utiliser uniquement le Wi-Fi",
      "settings.wifiOnly.hint": "Si vous souhaitez que l'application utilise vos données mobiles, laissez cette option désactivée. Pour économiser vos données, activez-la.",
      "settings.notifications": "Notifications",
      "settings.notifications.enabled": "Activées",
      "settings.notifications.enableDeviceLocationFirst": "Activez d'abord la localisation de l'appareil",
      "settings.notifications.disabled": "Désactivées",
      "settings.notifications.loading": "Chargement...",
      "settings.notifications.unavailable": "N/A (Mobile uniquement)",
      "settings.locations": "Localisation",
      "settings.locations.appAccessToLocation": "Accès de l'application à la localisation",
      "settings.locations.enabled": "Activée",
      "settings.locations.deviceLocation": "Localisation de l'appareil",
      "settings.locations.disabled": "Désactivée",
      "settings.locations.loading": "Chargement...",
      "settings.locations.goToAppSettings": "Aller aux paramètres de l'application",
      "settings.locations.goToPhoneSettings": "Aller aux paramètres du téléphone",
      "share": "Partager",
      "shared": "Partagé",
      "shareList": "Partager la liste",
      "submit": "Soumettre",
      "syncIndicator.popover.text": "Vos données sont en cours de synchronisation. Mais ne vous inquiétez pas ! Vous pouvez continuer à travailler et nous mettrons tout à jour entre-temps.",
      "task": "Tâche",
      "task_one": "Tâche",
      "task_other": "Tâches",
      "tasks": "Tâches",
      "timeZone": "Fuseau horaire",
      "today": "Aujourd'hui",
      "tomorrow": "Demain",
      "toolbar.tab.home": "Accueil",
      "toolbar.tab.reports": "Rapports",
      "toolbar.tab.settings": "Paramètres",
      "unarchive": "Désarchiver",
      "undefinedList.name": "Liste non définie",
      "update.title": "Faites la mise à niveau !",
      "update.body": "Une mise à jour de l'application est disponible ! Nous travaillons toujours pour vous offrir le meilleur.",
      "Yesterday": "Hier",

      "reports.header.completion": "Achèvement",
      "reports.header.createdBy": "Rapport créé par",
      "reports.header.reportRunOn": "Rapport exécuté le",
      "reports.header.streak": "# de jours consécutifs sans tâches en retard au {{date}}",
      "reports.header.mystreak": "Ma Série",
      "reports.config.onTime": "À l'heure",
      "reports.config.late": "En retard",
      "reports.config.lateDays": "En retard ({{days}} jours)",
      "reports.config.missed": "Omis",
      "reports.config.na": "N/A",
      "reports.config.status.tasks": "Tâches",
      "reports.config.status.task": "Tâche",
      "reports.config.status.noTasks": "Aucune tâche",
      "reports.config.status.dueDate": "Date d'échéance",
      "reports.config.status.completePct": "% Complété",
      "reports.config.status.complete": "Terminé",
      "reports.config.status.completed": "Complété",
      "reports.config.status.completedBy": "Complété par",
      "reports.config.status.completion": "Achèvement",
      "reports.config.status.photos": "Photos",
      "reports.config.status.photos.before": "(Avant)",
      "reports.config.status.photos.after": "(Après)",
      "reports.config.status.photos.deficiency": "(Déficience)",
      "reports.config.status.nearestAddress": "Adresse la plus proche",
      "reports.config.status.assignedTo": "Assigné à",
      "reports.config.status.comments": "Commentaires",
      "reports.config.status.notes": "Notes",
      "reportBuilder.previewPage.includeCompletedBy": "Inclure Complété par",
      "reportBuilder.previewPage.missedTasks": "Afficher seulement les tâches omises",
      "reports.config.noTasksFound": "Aucune tâche trouvée (dans la plage de dates fournie).",
      "reports.config.status.assignedTasks": "Tâches Assignées",
      "timetracking.weeklyTotal": "Total Hebdomadaire",
      "timetracking.dailyTotal": "Total Quotidien",
      "timetracking.hours": "Heures",
      "timetracking.weekBreakdown": "Répartition de la Semaine",
      "timetracking.startDate": "Date de Début",
      "timetracking.startTime": "Heure de Début",
      "timetracking.endDate": "Date de Fin",
      "timetracking.endTime": "Heure de Fin",
      "timetracking.clockedInAt": "Pointage Entrée à",
      "timetracking.clockedOutAt": "Pointage Sortie à",
      "timetracking.clockIn": "Pointer Entrée",
      "timetracking.clockOut": "Pointer Sortie",
      "timetracking.tab": "Temps",
      "timetracking.wait": "Attends !",
      "timetracking.clockInConfirmation": "Vous êtes pointé depuis plus de 12 heures, avez-vous oublié de pointer et vouliez-vous enregistrer votre entrée?",
      "timetracking.clockOutConfirmation": "Vous êtes pointé depuis plus de 12 heures, avez-vous oublié de pointer et vouliez-vous enregistrer votre entrée?",
      "timetracking.clockMeIn": "Oui, Pointer Entrée",
      "timetracking.stayClockedOut": "Non, Rester Dépointé",
      "timetracking.clockMeOut": "Non, Je Pointe la Sortie",
    }
  },
  "pt": {
    "translation": {
      "addFirstList": "Toque no \"+\" \n para adicionar uma lista",
      "addFirstTask": "Toque no \"+\" \n para adicionar uma tarefa",
      "addList": "Adicionar Lista",
      "addTask": "Adicionar Tarefa",
      "after": "Depois",
      "alert.offline.photo.header": "Atualmente Offline",
      "alert.offline.photo.message": "Por favor, volte online para baixar imagens.",
      "archive": "Arquivar",
      "archived": "Arquivado",
      "assign": "Atribuir",
      "assignList": "Atribuir Lista",
      "assignMenu.deleteConfirmation.header": "Sobrescrever Atribuições",
      "assignMenu.deleteConfirmation.message": "Qualquer atribuição existente desta lista será removida, e então atribuída à pessoa selecionada. \n \n Observação: Tarefas concluídas não serão reatribuídas.",
      "assignMenu.deselectUsers.assign": "Ninguém (Desatribuir)",
      "assignMenu.deselectUsers.share": "Ninguém (Descompartilhar)",
      "assignMenu.header.list.assign": "Atribuir lista a:",
      "assignMenu.header.list.share": "Compartilhar lista com:",
      "assignMenu.header.list.shareOwner": "Compartilhar / Adicionar proprietários:",
      "assignMenu.header.task.assign_one": "Atribuir tarefa a:",
      "assignMenu.header.task.assign_other": "Atribuir tarefas a:",
      "assignTask": "Atribuir Tarefa",
      "assignTask.to": "Atribuir tarefa a:",
      "banner.missedRequiredUpdate.main": "Uma Atualização é Necessária",
      "banner.writeAccessRestricted.info.body.nonPayment.1": "Devido a faturas em atraso/problemas de pagamento, o acesso da sua empresa foi limitado.",
      "banner.writeAccessRestricted.info.body.nonPayment.2": "Entre em contato com seu administrador para atualizar as informações de pagamento para que seu acesso possa continuar.",
      "banner.writeAccessRestricted.info.header": "Limitado",
      "banner.writeAccessRestricted.main": "O acesso está limitado",
      "back": "Voltar",
      "before": "Antes",
      "button.acknowledge": "OK",
      "button.later": "Mais tarde",
      "button.update": "Atualizar",
      "button.illAddTasks": "Vou Adicionar Tarefas",
      "button.addTasksForMe": "Adicionar Tarefas Para Mim",
      "calendarView.clear": "Limpar",
      "calendarView.repeat": "Repetir",
      "calendarView.time": "Hora",
      "cancel": "Cancelar",
      "change": "Alterar",
      "complete": "Concluir",
      "completeList": "Concluir Lista",
      "completeTask": "Concluir Tarefa",
      "completeTasks": "Concluir Tarefas",
      "completed": "Concluído",
      "completionReport": "Relatório de Conclusão",
      "confirmation.agreement.button.accept": "Aceitar",
      "confirmation.agreement.button.reject": "Não Aceitar",
      "confirmation.agreement.header.default": "Bem-vindo!",
      "confirmation.agreement.header.updated": "Atualizado",
      "confirmation.agreement.body.default": "Para usar Otuvy Frontline, você precisará aceitar nossa <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidade</a> e nossos <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Termos de Serviço</a>.",
      "confirmation.agreement.body.updated": "Nossos <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/frontline-eula`, `_system`, `location=yes`); return false;'>Termos de Serviço</a> e nossa <a class='ion-home color-primary item' href='#' onclick='window.open(`https://www.otuvy.com/privacy-policy`, `_system`, `location=yes`); return false;'>Política de Privacidade</a> foram atualizados. Para continuar usando Otuvy Frontline, você precisará aceitar essas atualizações.",
      "confirmation.delete.account.link": "Sim, excluir minha conta",
      "confirmation.delete.image.confirm": "Excluir imagem",
      "confirmation.delete.image.header": "Excluir Imagem",
      "confirmation.delete.image.message": "Você não poderá recuperar esta imagem depois de excluí-la.",
      "confirmation.delete.list.confirm": "Excluir a lista",
      "confirmation.delete.list.header": "Excluir esta lista?",
      "confirmation.delete.list.message": "Todas as suas tarefas (e suas fotos, notas, etc.) também serão excluídas.",
      "confirmation.delete.profileImage.header": "Tem certeza de que deseja remover sua imagem de perfil?",
      "confirmation.delete.task.confirm": "Excluir tarefa",
      "confirmation.delete.task.generic.confirm": "Excluir",
      "confirmation.delete.task.generic.header": "Confirmar Exclusão",
      "confirmation.delete.task.generic.message": "Tem certeza? (Todas as fotos, notas, etc. também serão excluídas.)",
      "confirmation.delete.task.header": "Excluir Tarefa",
      "confirmation.delete.task.message": "Tem certeza? (Todas as fotos, notas, etc. também serão excluídas.)",
      "confirmation.dueDate.apply": "Sim, aplicar a data de vencimento",
      "confirmation.dueDate.existingWillBeReplaced": "Qualquer data de vencimento existente será substituída",
      "confirmation.dueDate.replace": "Substituir Datas de Vencimento?",
      "contactSupport": "Entrar em Contato com o Suporte",
      "date": "Data",
      "dayOfTheWeek": "Dia da Semana",
      "default.list.name": "Lista Sem Título",
      "default.task.name": "Tarefa Sem Título",
      "deficiencies": "Deficiências",
      "delete": "Excluir",
      "deleteTasks": "Excluir tarefas",
      "deselect": "Desmarcar",
      "deselectAll": "Desmarcar Tudo",
      "done": "Concluído",
      "dueDate": "Data de Vencimento",
      "dueDateAndRepeat": "Data de Vencimento e Repetição...",
      "duplicate": "Duplicar",
      "duplicateList": "Duplicar Lista",
      "duplicateTask": "Duplicar tarefa",
      "duplicateTasks": "Duplicar tarefas",
      "edit": "Editar",
      "email": "Email",
      "employeeScorecard": "Quadro de Pontuação do Funcionário",
      "error.name.required": "O nome é obrigatório",
      "error.phone.invalid": "O telefone parece ser inválido",
      "error.phone.required": "O telefone é obrigatório",
      "error.signin.incorrectOTP": "Incorreto. Verifique o código em seu email e tente novamente.",
      "errorAlert.default.header": "Ops!",
      "errorAlert.default.message": "Encontramos um erro do nosso lado. Desculpe! Se isso acontecer várias vezes, fique à vontade para <a href='mailto:support@otuvy.com'>Entrar em Contato com o Suporte</a>.",
      "errorAlert.generateList.contentFilter.message": "Uma ou mais de suas respostas foram marcadas como contendo conteúdo inadequado. Por favor, modifique suas respostas e tente enviar novamente.<br/><br/>Se este erro ocorrer várias vezes, fique à vontade para <a href='mailto:support@otuvy.com'>Entrar em Contato com o Suporte</a>.",
      "errorAlert.generateList.generic.message": "Encontramos um problema ao criar a lista. Desculpe! Você pode tentar novamente.<br/><br/>Se acontecer várias vezes, fique à vontade para <a href='mailto:support@otuvy.com'>Entrar em Contato com o Suporte</a>.",
      "errorAlert.list.unavailable": "Parece que isso não está disponível para você. Se você tiver dúvidas, verifique com seu administrador ou <a href='mailto:support@otuvy.com'>Entre em Contato com o Suporte</a>.",
      "errorPage": "Oi, sou a página de erro.",
      "errorPage.backIntoIt": "Vamos voltar a isso.",
      "errorPage.header": "Oi, sou a página de erro.",
      "errorPage.message": "Desculpe, tivemos um problema do nosso lado. Não se preocupe, seu trabalho deve ser salvo automaticamente.",
      "errorPage.unavailableList.message": "'Parece que essa lista não está mais disponível'.",
      "errorPage.sorry": "Desculpe, tivemos um problema do nosso lado. Não se preocupe, seu trabalho deve ser salvo automaticamente.",
      "favorites": "Favoritos",
      "feedback.list.completed": "Bom trabalho! Você concluiu a lista.",
      "feedback.task.completed": "Bom trabalho! Tarefa concluída \"{{taskName}}\"",
      "generatedTask.instructions.duration": "({{duration}} min)",
      "incorrectOTP": "Incorreto. Verifique o código em seu email e tente novamente.",
      "instructions": "Instruções",
      "instructions.link.add": "+ Adicionar um Link",
      "instructions.link.delete": "Excluir Link",
      "instructions.link.delete.confirmation.text": "Tem certeza de que deseja excluir este link?",
      "instructions.link.textToDisplay.label": "Texto para Exibir (opcional)",
      "instructions.link.textToDisplay.placeholder": "ex. 10 Passos para Aparar o Gramado",
      "instructions.link.save": "Salvar Link",
      "label.selectAllCompletedTasks": "Selecionar todas as tarefas concluídas",
      "label.selectAllTasks": "Selecionar todas as tarefas",
      "label.task.instructions": "Instruções",
      "label.task.name": "Tarefa",
      "label.task.notes": "Notas",
      "label.task.taskName": "Tarefa",
      "link.deleteMyAccount": "Excluir minha conta",
      "link.faq": "Perguntas Frequentes",
      "link.feedback": "Dar Feedback",
      "link.privacyPolicy": "Política de Privacidade",
      "link.support": "Entrar em Contato com o Suporte",
      "link.termsAndConditions": "Termos e Condições",
      "link.termsOfService": "Termos de Serviço",
      "link.voteOnFeatures": "Votar em Recursos",
      "list": "Lista",
      "list_one": "Lista",
      "list_other": "Listas",
      "list.archive": "Arquivar Lista",
      "list.unarchive": "Desarquivar Lista",
      "list.owner": "Proprietário da Lista",
      "list.rename": "Renomear Lista",
      "listDetails.header": "Detalhes da Lista",
      "listName": "Nome da Lista",
      "lists": "Listas",
      "loading.generateList": "Criando tarefas...",
      "loading.generic": "Por favor, aguarde...",
      "logout": "Sair",
      "menu.action.hideArchivedLists": "Ocultar Listas Arquivadas",
      "menu.action.showArchivedLists": "Mostrar Listas Arquivadas",
      "menu.action.hideCompletedLists": "Ocultar Listas Concluídas",
      "menu.action.hideCompletedTasks": "Ocultar Tarefas Concluídas",
      "menu.action.scanQRCodes": "Escanear Códigos QR",
      "menu.action.select.lists": "Selecionar Listas",
      "menu.action.select.tasks": "Selecionar Tarefas",
      "menu.action.share.list": "Compartilhar Lista",
      "menu.action.shareListOrAddOwners": "Compartilhar Listas ou Adicionar Proprietários",
      "menu.action.showCompletedLists": "Mostrar Listas Concluídas",
      "menu.action.showCompletedTasks": "Mostrar Tarefas Concluídas",
      "menu.action.sort": "Classificar por...",
      "menu.duedateAndRepeat": "Data de Vencimento e Repetição",
      "menu.header.sort": "Classificar por...",
      "menu.helpAndAbout": "Ajuda & Sobre",
      "modal.generateList.header": "Perguntas",
      "modal.generateList.question1.accessibilityLabel": "Nome da Lista*",
      "modal.generateList.question1.label": "Vamos começar com a sua lista",
      "modal.generateList.question1.placeholder": "Nome da Lista, etc.",
      "modal.generateList.question2.label": "Que tipo de tarefas?",
      "modal.generateList.question2.placeholder": "ex. Limpeza, Reparo, Instalação, etc.",
      "modal.generateList.question3.label": "Qual é o objetivo principal da lista?",
      "modal.generateList.question3.placeholder": "Fornecer tarefas detalhadas para higienizar a cozinha.",
      "modal.generateList.button.accept": "Adicionar Tarefas para Mim",
      "multiSelectMenu.deselect.archived": "Desmarcar Todos Arquivados",
      "multiSelectMenu.deselect.completed": "Desmarcar Todos Concluídos",
      "multiSelectMenu.deselect.incomplete": "Desmarcar Todos Incompletos",
      "multiSelectMenu.dueDateAndRepeat": "Data de Vencimento / Repetição",
      "multiSelectMenu.photoRequired": "Exigir Foto",
      "multiSelectMenu.photoRequired.makeOptional": "Desativar Foto",
      "multiSelectMenu.select.archived": "Selecionar Todos Arquivados",
      "multiSelectMenu.select.completed": "Selecionar Todos Concluídos",
      "multiSelectMenu.select.incomplete": "Selecionar Todos Incompletos",
      "name": "Nome",
      "newCodeRequest": "Envie-me um novo código",
      "newVerificationCodeSent.header": "Ainda aguardando um código?",
      "newVerificationCodeSent.message": "Use esse tempo para meditar ou confira <a href='https://www.otuvy.com/' target='_blank' rel='noreferrer noopener'>Otuvy.com</a> para saber mais sobre nós. Você também pode entrar em contato com o administrador da sua conta para solucionar o problema.",
      "none": "Nenhum",
      "notes": "Notas",
      "notifications.notification.inApp": "Notificações In-App",
      "notifications.notification.gotoOSSettings.label": "Configurações do Telefone",
      "notifications.listDone": "Lista Concluída",
      "notifications.tasksGetAssignedToMe": "Tarefas Atribuídas a Mim",
      "notifications.listsGetAssignedToMe": "Tornar-se Proprietário da Lista",
      "notifications.pastDueTasks": "Tarefas Atrasadas",
      "notifications.pastDueTasks.assignedToMe": "Atribuídas a mim",
      "notifications.pastDueTasks.oneOffs": "Únicas",
      "notifications.pastDueTasks.repeating": "recorrentes",
      "notifications.pastDueTasks.onSharedList": "Em uma Lista Compartilhada",
      "popover.generateList.offline": "Você precisa estar online para adicionar tarefas automaticamente.",
      "offline": "Offline",
      "offlineIndicator.popover.text": "Seu telefone está offline. Mas não se preocupe! Você pode continuar trabalhando e atualizaremos tudo quando estiver online novamente.",
      "offlineNoticeHeader": "Sem internet \t(",
      "offlineNoticeMessage": "Devemos nos reconectar em breve. Para verificar se não é apenas nosso aplicativo, você pode... <br/> <br/> <ul><li>Verificar as configurações do telefone</li><li>Verificar outros aplicativos</li><li>Verificar a intensidade do sinal Wi-Fi</li></ul><br/>E não se preocupe, salvamos seu trabalho conforme você avança!",
      "option.sort.activity": "Atividade Recente",
      "option.sort.assignedTo": "Atribuído a",
      "option.sort.created": "Data de Criação",
      "option.sort.due": "Data de Vencimento",
      "option.sort.name": "Nome",
      "otpCode": "Código",
      "otpPrompt": "Enviaremos um código para o seu email para que você possa começar a usar o Otuvy Frontline",
      "otpSent": "Enviamos um código de verificação para o seu email. Por favor, insira-o abaixo.",
      "owner": "Proprietário",
      "preview": "Pré-visualização",
      "phone": "Telefone",
      "picturesAbbreviated": "Fotos",
      "placeholder.email": "jane.doe@exemplo.com",
      "placeholder.generic": "Toque para inserir texto",
      "placeholder.name": "Jane Doe",
      "placeholder.otp": "Seu código de verificação",
      "product.version": "v.",
      "registrationGreeting": "Bem-vindo! Vamos terminar de configurar sua conta...",
      "remove": "Remover",
      "repeat": "Repetir",
      "repeatMenu.custom": "Personalizado",
      "repeatMenu.custom.day": "Dia",
      "repeatMenu.custom.days": "Dias",
      "repeatMenu.custom.fifth": "Quinto",
      "repeatMenu.custom.first": "Primeiro",
      "repeatMenu.custom.fourth": "Quarto",
      "repeatMenu.custom.friday": "Sexta-feira",
      "repeatMenu.custom.monday": "Segunda-feira",
      "repeatMenu.custom.month": "Mês",
      "repeatMenu.custom.months": "Meses",
      "repeatMenu.custom.on": "No",
      "repeatMenu.custom.saturday": "Sábado",
      "repeatMenu.custom.second": "Segundo",
      "repeatMenu.custom.sunday": "Domingo",
      "repeatMenu.custom.third": "Terceiro",
      "repeatMenu.custom.thursday": "Quinta-feira",
      "repeatMenu.custom.title": "Repete a cada:",
      "repeatMenu.custom.tuesday": "Terça-feira",
      "repeatMenu.custom.wednesday": "Quarta-feira",
      "repeatMenu.custom.week": "Semana",
      "repeatMenu.custom.weeks": "Semanas",
      "repeatMenu.custom.year": "Ano",
      "repeatMenu.custom.years": "Anos",
      "repeatMenu.daily": "Diariamente",
      "repeatMenu.monthly": "Mensalmente",
      "repeatMenu.monthlyByWeekday": "Mensalmente",
      "repeatMenu.none": "Nenhum",
      "repeatMenu.weekdays": "Todos os dias da semana (Seg-Sex)",
      "repeatMenu.weekly": "Semanalmente",
      "repeatMenu.yearly": "Anualmente",
      "reportBuilder.modal.deleteConfirmation.header": "Espere!",
      "reportBuilder.modal.deleteConfirmation.message": "Se você sair agora, seu relatório não será enviado e será necessário criar um novo. Deseja continuar?",
      "reportBuilder.modal.deleteConfirmation.button.confirm": "Sim, Excluir Este Relatório",
      "reportBuilder.modal.header.completionReport": "Relatório de Conclusão",
      "reportBuilder.modal.header.employeeScorecard": "Quadro de Pontuação do Funcionário",
      "reportBuilder.whoGetsItPage.header": "Quem recebe?",
      "reportBuilder.whoGetsItPage.message": "Insira um ou mais emails.",
      "reportBuilder.whoGetsItPage.input.label": "Enviar para:",
      "reportBuilder.dateRangePage.header": "Período",
      "reportBuilder.dateRangePage.message": "Incluir dados deste período...",
      "reportBuilder.dateRangePage.range.last24hours": "Últimas 24 horas",
      "reportBuilder.dateRangePage.range.last7days": "Últimos 7 dias",
      "reportBuilder.dateRangePage.range.last30days": "Últimos 30 dias",
      "reportBuilder.dateRangePage.range.last90days": "Últimos 90 dias",
      "reportBuilder.dateRangePage.range.lastFullMonth": "Último mês completo",
      "reportBuilder.dateRangePage.range.custom": "Período Personalizado... (veja abaixo)",
      "reportBuilder.dateRangePage.range.custom.firstDate": "Primeira data:",
      "reportBuilder.dateRangePage.range.custom.lastDate": "Última data:",
      "reportBuilder.employeeSelectionPage.header": "De quem são os dados",
      "reportBuilder.employeeSelectionPage.message": "De quem devemos incluir as tarefas?",
      "reportBuilder.previewPage.details.listsIncluded": "Listas Incluídas:",
      "reportBuilder.previewPage.details.recipients": "Destinatários:",
      "reportBuilder.previewPage.emailNow": "Enviar Email Agora",
      "reportBuilder.previewPage.radioGroup.header": "Mostrar",
      "reportBuilder.previewPage.radioGroup.option.addComments": "Adicionar Comentários",
      "reportBuilder.previewPage.radioGroup.option.lists": "Listas (recomendado)",
      "reportBuilder.previewPage.radioGroup.option.listsAndTasks": "Listas e tarefas",
      "reportBuilder.previewPage.radioGroup.option.notes": "Notas das tarefas",
      "reportBuilder.previewPage.radioGroup.option.reportName": "Nome do Relatório",
      "reportBuilder.whichListPage.clearFilters": "Limpar Filtros",
      "reportBuilder.whichListsPage.header": "Quais Listas?",
      "reportBuilder.whoseDataPage.header": "Os dados de quem?",
      "reportBuilder.filterSearchbar.byListName": "Por nome da lista",
      "reportBuilder.filterSearchbar.byPerson": "Por pessoa",
      "reportBuilder.toast.deleted": "Relatório Excluído!",
      "reportBuilder.toast.submitted": "Relatório Enviado!",
      "reports": "Relatórios",
      "reports.comletionReport": "Relatório de Conclusão",
      "reports.newCompletionReport": "Novo Relatório de Conclusão",
      "reports.employeeScorecard": "Quadro de Pontuação do Funcionário",
      "reports.newEmployeeScorecard": "Novo Quadro de Pontuação do Funcionário",
      "required": "Obrigatório",
      "requirePhoto": "Exigir Foto",
      "requirePhoto.makeOptional": "Tornar Opcional",
      "requirePhoto.takePhoto": "Tirar Foto (obrigatório)",
      "rrule.on.weekly": "no",
      "rrule.on.yearly": "no",
      "rrule.weekdays": "(Seg-Sex)",
      "searchForLists": "Pesquisar listas...",
      "searchForTasks": "Pesquisar tarefas...",
      "searchForTimeZone": "Pesquisar fuso horário...",
      "searchInput.placeholder": "Pesquisar...",
      "select": "Selecionar",
      "selected": "Selecionado",
      "selectAll": "Selecionar Tudo",
      "sendCode": "Enviar Código de Verificação",
      "settings": "Configurações",
      "settings.darkMode": "Modo Escuro",
      "settings.language": "Idioma",
      "settings.wifiOnly": "Usar Apenas Wi-Fi",
      "settings.wifiOnly.hint": "Se você quer que o app use os dados do seu celular, deixe esta opção desativada. Se você deseja preservar seus dados móveis, ative-a.",
      "settings.notifications": "Notificações",
      "settings.notifications.enabled": "Habilitadas",
      "settings.notifications.enableDeviceLocationFirst": "Habilite a Localização do Dispositivo Primeiro",
      "settings.notifications.disabled": "Desabilitadas",
      "settings.notifications.loading": "Carregando...",
      "settings.notifications.unavailable": "N/D (Apenas Mobile)",
      "settings.locations": "Localização",
      "settings.locations.appAccessToLocation": "Acesso do App à Localização",
      "settings.locations.enabled": "Habilitada",
      "settings.locations.deviceLocation": "Localização do Dispositivo",
      "settings.locations.disabled": "Desabilitada",
      "settings.locations.loading": "Carregando...",
      "settings.locations.goToAppSettings": "Ir para Configurações do App",
      "settings.locations.goToPhoneSettings": "Ir para Configurações do Telefone",
      "share": "Compartilhar",
      "shared": "Compartilhado",
      "shareList": "Compartilhar Lista",
      "submit": "Enviar",
      "syncIndicator.popover.text": "Seus dados estão sincronizando. Mas não se preocupe! Você pode continuar trabalhando e atualizaremos tudo enquanto isso.",
      "task": "Tarefa",
      "task_one": "Tarefa",
      "task_other": "Tarefas",
      "tasks": "Tarefas",
      "timeZone": "Fuso Horário",
      "today": "Hoje",
      "tomorrow": "Amanhã",
      "toolbar.tab.home": "Início",
      "toolbar.tab.reports": "Relatórios",
      "toolbar.tab.settings": "Configurações",
      "unarchive": "Desarquivar",
      "undefinedList.name": "Lista Indefinida",
      "update.title": "Atualize!",
      "update.body": "Uma atualização do app está disponível para você! Estamos sempre trabalhando para trazer o melhor para você.",
      "Yesterday": "Ontem",

      "reports.header.completion": "Conclusão",
      "reports.header.createdBy": "Relatório criado por",
      "reports.header.reportRunOn": "Relatório executado em",
      "reports.header.streak": "# de dias seguidos sem tarefas atrasadas até {{date}}",
      "reports.header.mystreak": "Minha Sequência",
      "reports.config.onTime": "No Prazo",
      "reports.config.late": "Atrasado",
      "reports.config.lateDays": "Atrasado ({{days}} dias)",
      "reports.config.missed": "Perdido",
      "reports.config.na": "N/A",
      "reports.config.status.tasks": "Tarefas",
      "reports.config.status.task": "Tarefa",
      "reports.config.status.noTasks": "Nenhuma tarefa",
      "reports.config.status.dueDate": "Data de Vencimento",
      "reports.config.status.completePct": "% Concluído",
      "reports.config.status.complete": "Concluído",
      "reports.config.status.completed": "Concluído",
      "reports.config.status.completedBy": "Concluído por",
      "reports.config.status.completion": "Conclusão",
      "reports.config.status.photos": "Fotos",
      "reports.config.status.photos.before": "(Antes)",
      "reports.config.status.photos.after": "(Depois)",
      "reports.config.status.photos.deficiency": "(Deficiência)",
      "reports.config.status.nearestAddress": "Endereço mais próximo",
      "reports.config.status.assignedTo": "Atribuído a",
      "reports.config.status.comments": "Comentários",
      "reports.config.status.notes": "Notas",
      "reportBuilder.previewPage.includeCompletedBy": "Incluir Concluído por",
      "reportBuilder.previewPage.missedTasks": "Mostrar apenas tarefas perdidas",
      "reports.config.noTasksFound": "Nenhuma tarefa encontrada (no intervalo de datas fornecido).",
      "reports.config.status.assignedTasks": "Tarefas Atribuídas",
      "timetracking.weeklyTotal": "Total Semanal",
      "timetracking.dailyTotal": "Total Diário",
      "timetracking.hours": "Horas",
      "timetracking.weekBreakdown": "Detalhamento da Semana",
      "timetracking.startDate": "Data de Início",
      "timetracking.startTime": "Hora de Início",
      "timetracking.endDate": "Data de Término",
      "timetracking.endTime": "Hora de Término",
      "timetracking.clockedInAt": "Entrada registrada às",
      "timetracking.clockedOutAt": "Saída registrada às",
      "timetracking.clockIn": "Registrar Entrada",
      "timetracking.clockOut": "Registrar Saída",
      "timetracking.tab": "Horas",
      "timetracking.wait": "Espere!",
      "timetracking.clockInConfirmation": "Você está registrado há mais de 12 horas. Você esqueceu de bater o ponto e queria registrar a entrada?",
      "timetracking.clockOutConfirmation": "Você está registrado há mais de 12 horas. Você esqueceu de bater o ponto e queria registrar a entrada?",
      "timetracking.clockMeIn": "Sim, Registrar Entrada",
      "timetracking.stayClockedOut": "Não, Manter-me Fora",
      "timetracking.clockMeOut": "Não, Estou Registrando a Saída"
    }
  },
}

export default resources