import { IonAlert } from '@ionic/react';
import { addTimePunch } from '../utils/timePunchUtils';
import { t } from 'i18next';

export interface ClockOutConfirmationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsClockedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClockOutConfirmation: React.FC<ClockOutConfirmationProps> = ({
  isOpen,
  setIsOpen,
  setIsClockedIn,
  setIsLoading,
}) => {
  const onClockOut = async () => {
    setIsLoading(true);
    try {
      await addTimePunch(true);
      setIsClockedIn(false);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClockIn = async () => {
    setIsLoading(true);
    try {
      await addTimePunch(false);
      setIsClockedIn(true);
      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <IonAlert
      mode="md"
      cssClass="clock-in-confirmation"
      header={t('timetracking.wait')}
      message={t('timetracking.clockOutConfirmation')}
      isOpen={isOpen}
      buttons={[
        {
          text: t('timetracking.clockMeIn'),
          role: 'cancel',
          handler: onClockIn,
        },
        {
          text: t('timetracking.clockMeOut'),
          role: 'destructive',
          handler: onClockOut,
        },
      ]}
      onWillDismiss={() => setIsOpen(false)}
    />
  );
};

export default ClockOutConfirmation;
