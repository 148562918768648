import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonText,
  IonRow,
  TextareaChangeEventDetail,
  IonPopover,
  createAnimation,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { MaxFieldLengths } from '../../constants/constants';
import CustomTextarea from '../general/CustomTextarea';
import { _list, _task } from '../../utils/state/model/implementations/ImplementationFactory';
import { capitalizeFirstLetter, logFriendlyObject } from '@otuvy/common-utils';
import { cloudOfflineOutline, colorWandOutline } from 'ionicons/icons';
import useNetworkStatus from '../../sync/network/useNetworkStatus';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';

import type { Animation } from '@ionic/react';
import { isPlatform } from '@ionic/core';
import AiModal from './AiModal';

export const showInputState = atom<boolean>({
  key: 'showInputState',
  default: false,
});

interface AddInputTypeProps {
  inputType: 'addTask' | 'addList';
  listID?: string;
}

const AddInputType: React.FC<AddInputTypeProps> = ({ inputType, listID }) => {
  const setShowInput = useSetRecoilState(showInputState);

  const customTexareaInputRef = React.useRef<HTMLIonTextareaElement | null>(null);
  const [name, setName] = useState<string>('');
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();
  const { isOnline } = useNetworkStatus();

  const createList: boolean = inputType === 'addList' && name.length > 0;
  const maxLength: number = inputType === 'addList' ? MaxFieldLengths.LIST_NAME : MaxFieldLengths.TASK_NAME;

  const rowRef = useRef<HTMLIonRowElement>(null);
  const animation = useRef<Animation | null>(null);

  useEffect(() => {
    let addInputTypeKeyboardEvent: any;
    let addInputTypeKeyboardEvent2: any;

    const setupKeyboardListeners = async () => {
      if (isPlatform('hybrid') && isPlatform('ios')) {
        addInputTypeKeyboardEvent = await Keyboard.addListener('keyboardWillShow', (e) => {
          Keyboard.setResizeMode({ mode: KeyboardResize.None });

          if (animation.current === null) {
            animation.current = createAnimation()
              .addElement(rowRef.current!)
              .fill('both')
              .duration(200)
              .fromTo('transform', 'translate3d(0, 0, 0)', `translate3d(0, -${e.keyboardHeight - 25}px, 0)`)
              .fromTo('opacity', '0', '1');

            animation.current.play();
          }
        });

        addInputTypeKeyboardEvent2 = await Keyboard.addListener('keyboardWillHide', () => {
          Keyboard.setResizeMode({ mode: KeyboardResize.Native });
        });
      }
    };

    setupKeyboardListeners();

    return () => {
      addInputTypeKeyboardEvent?.remove();
      addInputTypeKeyboardEvent2?.remove();
      if (isPlatform('hybrid') && isPlatform('ios')) Keyboard.setResizeMode({ mode: KeyboardResize.Native }); // Ensure native mode is restored
    };
  }, []);

  const handleBlur = () => {
    if (isClicked) return;
    handleSubmit();
    // Keep the input open if we're going to the newly created list so they can start entering tasks
    if (!createList) {
      setShowInput(false);
    }
  };

  const handleSubmit = () => {
    if (name.length > 0) {
      submitForRecordCreation();
    }
  };

  const submitForRecordCreation = () => {
    addRecord().then((data: string) => {
      if (inputType === 'addList') {
        navigateToList(data);
      }
    });
    setName('');
  };

  const addRecord = async (): Promise<string> => {
    try {
      if (inputType === 'addTask' && listID) {
        await _task.bulkCreateTasksFromStringWithNewLines({ names: name, listId: listID });
      }
      if (inputType === 'addList') {
        const response =
          name.trim().length > 0
            ? await _list.createListFromName(name)
            : await _list.createListFromName(t('default.list.name'));
        if (!response) throw new Error('Error');
        return response.listId;
      }
      return '';
    } catch (err) {
      console.log('Error adding record', logFriendlyObject(err));
      return '';
    }
  };

  const navigateToList = (listId: string = '') => {
    history.push(`/list/${listId}`);
  };

  const handleCustomTextareaChange = (event: CustomEvent<TextareaChangeEventDetail>) => {
    if (!event.detail.value) return setName('');
    const value = name.length === 0 ? capitalizeFirstLetter(event.detail.value) : event.detail.value;
    setName(value);
  };

  const focusInput = () => {
    if (customTexareaInputRef.current) {
      customTexareaInputRef.current.focus();
    }
  };

  const onDismissOfflineTooltip = () => {
    setIsClicked(false);
    focusInput();
  };

  return (
    <>
      <IonRow
        ref={rowRef}
        class="ion-justify-content-center ion-align-items-center"
        style={{ background: 'var(--ion-background-color)' }}
      >
        <IonCol size="12" sizeMd="7">
          <CustomTextarea
            ref={customTexareaInputRef}
            marginBottom="0px"
            accessibilityLabel={inputType}
            name={`addInputType-${inputType}`}
            value={name}
            placeholder={t(inputType)!}
            maxlength={name === '' ? undefined : maxLength}
            onIonChange={handleCustomTextareaChange}
            onIonBlur={handleBlur}
            // Blur event would get triggered when navigating to the list page,
            // causing a double submission, so we'll do it now instead
            doesEnterKeyBlur={name === '' || inputType === 'addList'}
            onEnterKey={inputType === 'addTask' ? handleSubmit : undefined}
            shouldNotScroll={inputType === 'addList'}
          />
          {inputType === 'addList' && (
            <div className="d-flex ion-justify-content-between ion-padding-horizontal ion-align-items-center">
              <div
                onMouseDown={() => setIsClicked(true)}
                className={`cursor-pointer font-weight-600 d-flex ion-align-items-center ${
                  isOnline ? '' : 'disabled-appearance-only'
                }`}
              >
                <IonIcon color="primary" icon={isOnline ? colorWandOutline : cloudOfflineOutline} />
                <IonText className="ion-margin-start" color="primary">
                  {t('button.addTasksForMe')}
                </IonText>
              </div>
              <IonPopover
                isOpen={isClicked && !isOnline}
                className="popover-menu offline-popover"
                onDidDismiss={onDismissOfflineTooltip}
              >
                <IonContent>
                  <div style={{ padding: '0.5rem 1rem' }}>{t('popover.generateList.offline')}</div>
                </IonContent>
              </IonPopover>

              <IonButton
                shape="round"
                style={{ width: '8rem' }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {t('button.illAddTasks')}
              </IonButton>
            </div>
          )}
        </IonCol>
      </IonRow>
      <br />
      {inputType === 'addList' && (
        <AiModal
          isOpen={isClicked && isOnline}
          close={() => setIsClicked(false)}
          name={name}
          focusInputEvent={focusInput}
        />
      )}
    </>
  );
};

export default AddInputType;
