import {
  IonCol,
  IonGrid,
  IonIcon,
  IonMenuToggle,
  IonRow,
  IonToolbar,
  IonRouterLink,
  IonText,
  IonFooter,
  isPlatform,
} from '@ionic/react';

import { barChart, homeOutline, settingsSharp, timeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilValue } from 'recoil';
import { useEffect, useRef } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { multiSelectItemsState } from '../checklist/lists/state/useMultiSelectTasks';
import { useLocation } from 'react-router-dom';
import useAndroidBackButtonNavigation from '../../hooks/useAndroidBackButtonNavigation';
import { multiSelectListItemsState } from '../checklist/lists/state/useMultiSelectLists';
import { EnvironmentConfig, getFlag } from '../../utils/environmentUtils';
import { organizationSettingsState } from '../../App';
import { ScreenOrientation } from '@capacitor/screen-orientation';

interface MenuToolbarProps {
  onMouseUp?: React.MouseEventHandler<HTMLIonToolbarElement>;
  isErrorPage?: boolean;
}

export const fullscreenModeState = atom<boolean>({
  key: 'fullscreenModeState',
  default: false,
});

export const mouseUpState = atom<boolean>({
  key: 'mouseUpState',
  default: false,
});

export const keyboardShownState = atom<boolean>({
  key: 'keyboardShownState',
  default: false,
});

const MenuToolbar: React.FC<MenuToolbarProps> = ({ onMouseUp, isErrorPage }) => {
  return <>{isErrorPage ? <ErrorMenuToolbar onMouseUp={onMouseUp} /> : <DefaultMenuToolbar onMouseUp={onMouseUp} />}</>;
};

export default MenuToolbar;

const DefaultMenuToolbar: React.FC<MenuToolbarProps> = ({ onMouseUp }) => {
  const selectedItems = useRecoilValue(multiSelectItemsState);
  const selectedListItems = useRecoilValue(multiSelectListItemsState);
  const isFullscreenMode = useRecoilValue(fullscreenModeState);
  const organizationSettings = useRecoilValue(organizationSettingsState);
  const { pathname } = useLocation();
  useAndroidBackButtonNavigation({ pathname });

  const footerRef = useRef<HTMLIonFooterElement>(null);

  useEffect(() => {
    let menuToolbarKeyboardWillShow: any;
    let menuToolbarKeyboardWillHide: any;

    const setupKeyboardListeners = async () => {
      if (isPlatform('hybrid')) {
        menuToolbarKeyboardWillShow = await Keyboard.addListener('keyboardWillShow', async () => {
          const orientation = await ScreenOrientation.orientation();
          ScreenOrientation.lock({ orientation: orientation.type });
          footerRef.current?.style.setProperty('display', 'none');
        });

        menuToolbarKeyboardWillHide = await Keyboard.addListener('keyboardWillHide', () => {
          ScreenOrientation.unlock();
          footerRef.current?.style.setProperty('display', 'block');
        });
      }
    };

    setupKeyboardListeners();

    return () => {
      menuToolbarKeyboardWillShow?.remove();
      menuToolbarKeyboardWillHide?.remove();
    };
  }, []);

  const hideMenuToolbar: boolean = selectedItems.length > 0 || isFullscreenMode || selectedListItems.length > 0;
  const { t } = useTranslation();

  return !hideMenuToolbar ? (
    <IonFooter ref={footerRef}>
      <IonToolbar onMouseUp={onMouseUp}>
        <IonGrid fixed={true}>
          <IonRow class="ion-justify-content-center">
            <IonCol class="ion-text-center">
              <IonRouterLink routerLink="/list" routerDirection={pathname.includes('list') ? 'back' : 'root'}>
                <IonIcon
                  color={pathname.includes('list') ? 'primary' : 'custom-gray'}
                  size="large"
                  icon={homeOutline}
                />
                <div>
                  <IonText color={pathname.includes('list') ? 'primary' : 'custom-gray'}>
                    {t('toolbar.tab.home')}
                  </IonText>
                </div>
              </IonRouterLink>
            </IonCol>
            <IonCol class="ion-text-center">
              <IonRouterLink routerLink="/reports" routerDirection="root">
                <IonIcon
                  color={pathname.includes('reports') ? 'primary' : 'custom-gray'}
                  size="large"
                  icon={barChart}
                />
                <IonText color={pathname.includes('reports') ? 'primary' : 'custom-gray'}>
                  <div>{t('toolbar.tab.reports')}</div>
                </IonText>
              </IonRouterLink>
            </IonCol>
            {/* Time Tracking Page */}
            {getFlag(EnvironmentConfig.TIME_TRACKING) && organizationSettings.timeTrackingEnabled && (
              <IonCol class="ion-text-center">
                <IonRouterLink routerLink="/timetracking" routerDirection="root">
                  <IonIcon
                    color={pathname.includes('timetracking') ? 'primary' : 'custom-gray'}
                    size="large"
                    icon={timeOutline}
                  />
                  <IonText color={pathname.includes('timetracking') ? 'primary' : 'custom-gray'}>
                    <div>{t('timetracking.tab')}</div>
                  </IonText>
                </IonRouterLink>
              </IonCol>
            )}
            <IonCol class="ion-text-center">
              <IonMenuToggle>
                <IonIcon color="custom-gray" size="large" icon={settingsSharp} />
                <IonText color="medium">
                  <div>{t('toolbar.tab.settings')}</div>
                </IonText>
              </IonMenuToggle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  ) : null;
};

const ErrorMenuToolbar: React.FC<MenuToolbarProps> = ({ onMouseUp }) => {
  const { t } = useTranslation();

  return (
    <IonFooter>
      <IonToolbar onMouseUp={onMouseUp}>
        <IonGrid fixed={true}>
          <IonRow class="ion-justify-content-center">
            <IonCol class="ion-text-center">
              <IonRouterLink href="/list">
                <IonIcon color={'primary'} size="large" icon={homeOutline} />
                <div>
                  <IonText color={'primary'}>{t('toolbar.tab.home')}</IonText>
                </div>
              </IonRouterLink>
            </IonCol>
            <IonCol class="ion-text-center">
              <IonIcon color={'custom-gray'} size="large" icon={barChart} />
              <IonText color={'custom-gray'}>
                <div>{t('toolbar.tab.reports')}</div>
              </IonText>
            </IonCol>
            <IonCol class="ion-text-center">
              <IonIcon color="custom-gray" size="large" icon={settingsSharp} />
              <IonText color="medium">
                <div>{t('toolbar.tab.settings')}</div>
              </IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </IonFooter>
  );
};
