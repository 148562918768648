import { IonAlert } from '@ionic/react';
import { addTimePunch } from '../utils/timePunchUtils';

import './ClockMeInCompleteConfirmation.css';
import { t } from 'i18next';

export interface ClockMeInCompleteConfirmationProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirmationDismiss: () => void;
}

const ClockMeInCompleteConfirmation: React.FC<ClockMeInCompleteConfirmationProps> = ({
  isOpen,
  setIsOpen,
  onConfirmationDismiss,
}) => {
  const onClockMeIn = async () => {
    try {
      addTimePunch(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonAlert
      mode="md"
      cssClass="clock-in-confirmation"
      header={t('timetracking.wait')}
      message={t('timetracking.clockInConfirmation')}
      isOpen={isOpen}
      buttons={[
        {
          text: t('timetracking.clockMeIn'),
          handler: onClockMeIn,
        },
        {
          text: t('timetracking.stayClockedOut'),
          role: 'cancel',
          cssClass: 'clock-me-in-danger-color',
        },
      ]}
      onWillDismiss={() => {
        setIsOpen(false);
        onConfirmationDismiss();
      }}
    />
  );
};

export default ClockMeInCompleteConfirmation;
