import {
  InputChangeEventDetail,
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonItem,
  IonList,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { t } from 'i18next';
import { DateTime } from 'luxon';
import { Dispatch, useMemo, useRef, useState } from 'react';
import { slideFromRightEnterAnimation, slideFromRightLeaveAnimation } from '../utils/calendarAnimation';
import { Virtuoso } from 'react-virtuoso';

interface TimezoneSelectProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timezone: string;
  setTimezone: Dispatch<{ type: 'SET_TIMEZONE'; payload: { timezone: string } }>;
}
interface InputCustomEvent extends CustomEvent {
  detail: InputChangeEventDetail;
  target: HTMLIonInputElement;
}

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ isOpen, setIsOpen, timezone, setTimezone }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const radioGroupRef = useRef<HTMLIonRadioGroupElement>(null);
  //TODO: Find the proper type for the virtuoso ref, but it looks like they don't have types for this yet
  const virtuoso = useRef<any>(null);
  const timezonesArr = useMemo(() => {
    if (searchInputValue === '') return (Intl as any).supportedValuesOf('timeZone');
    return (Intl as any)
      .supportedValuesOf('timeZone')
      .filter((timezone: string) => timezone.toLowerCase().includes(searchInputValue.toLowerCase()));
  }, [searchInputValue]);

  function scrollToIndex() {
    virtuoso.current?.scrollToIndex({
      index: timezonesArr.indexOf(timezone),
      align: 'center',
    });
  }

  function cancelClickHandler() {
    setIsOpen(false);
  }

  function doneClickHandler() {
    setTimezone({ type: 'SET_TIMEZONE', payload: { timezone: radioGroupRef.current?.value } });
    setIsOpen(false);
  }

  function onInputChangeHanlder(e: InputCustomEvent) {
    console.log('onInputChangeHanlder', e.detail.value);
    if (!e.detail.value) return setSearchInputValue('');
    setSearchInputValue(e.detail.value.toString());
  }

  return (
    <>
      <IonModal
        onIonModalDidPresent={scrollToIndex}
        isOpen={isOpen}
        enterAnimation={slideFromRightEnterAnimation}
        leaveAnimation={slideFromRightLeaveAnimation}
      >
        <IonContent class="ion-padding-horizontal" style={{ '--background': 'var(--toolbar-background)' }}>
          <IonGrid style={{ height: '90%' }}>
            <IonInput
              value={searchInputValue}
              debounce={500}
              class="custom-input ion-margin-top"
              placeholder={t('searchForTimeZone') as string}
              onIonChange={onInputChangeHanlder}
            />
            <IonList style={{ background: 'none', height: '100%' }}>
              <IonRadioGroup ref={radioGroupRef} value={timezone}>
                <Virtuoso
                  ref={virtuoso}
                  data={timezonesArr}
                  style={{ height: '100%' }}
                  totalCount={timezonesArr.length}
                  itemContent={(index: number) => {
                    return (
                      <IonItem key={index} color="custom-overlay" lines="none">
                        <IonRadio labelPlacement="end" justify="start" value={timezonesArr[index]}>
                          <IonText style={{ fontSize: 'var(--ion-label-font-size) !important' }}>
                            {timezonesArr[index]}, GMT{DateTime.local({ zone: timezonesArr[index] }).toFormat('ZZ')}
                          </IonText>
                        </IonRadio>
                      </IonItem>
                    );
                  }}
                />
                {/* {timezonesArr.map((timezone: string, index: number) => {
                  return (
                    <IonItem key={index} color="custom-overlay" lines="none">
                      <IonRadio class="ion-margin-end" />
                      <IonLabel>
                        {timezone}, GMT{DateTime.local({ zone: timezone }).toFormat('ZZ')}
                      </IonLabel>
                    </IonItem>
                  );
                })} */}
              </IonRadioGroup>
            </IonList>
          </IonGrid>
        </IonContent>
        <IonFooter class="ion-no-border">
          <IonToolbar>
            <IonButton slot="start" fill="clear" onClick={cancelClickHandler}>
              <IonText color="primary">{t('cancel')}</IonText>
            </IonButton>
            <IonButton slot="end" fill="clear" onClick={doneClickHandler}>
              <IonText color="primary">{t('done')}</IonText>
            </IonButton>
          </IonToolbar>
        </IonFooter>
      </IonModal>
    </>
  );
};

export default TimezoneSelect;
