import { ToastOptions, useIonToast } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { toastQueueState } from './useToastQueue';
import { close } from 'ionicons/icons';

const useToastPresenter = () => {
  const [queue, setQueue] = useRecoilState(toastQueueState);
  const [isPresenting, setIsPresenting] = useState<boolean>(false);
  const [present] = useIonToast();

  const defaultToastOptions: ToastOptions = {
    buttons: [
      {
        icon: close,
        side: 'end',
        role: 'cancel',
        htmlAttributes: {
          class: 'custom-button',
        },
      },
    ],
  };

  useEffect(() => {
    if (!isPresenting && queue.length > 0) {
      present({
        ...defaultToastOptions,
        ...queue[0],
        onDidDismiss: () => setIsPresenting(false),
      });
      setQueue(queue.slice(1));
      setIsPresenting(true);
    }
  }, [queue, isPresenting]);
};

export default useToastPresenter;
